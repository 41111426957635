import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, Space, Button, Tooltip, Alert } from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import db from "../utils/db";

import { collection } from "firebase/firestore";

import { useCollection } from "react-firebase-hooks/firestore";

import { useAuth } from "../App";
import ZengradeLogo from "../assets/zengrade-logo-nobackground.svg";
import ZengradeLogoDeactivated from "../assets/zengrade-logo-nobackground-deactivated.svg";

import "./ViewExamsPage.css";

const ViewExamsPage = () => {
  const { isLoaded, isSignedIn, user } = useAuth();
  let navigate = useNavigate();

  const [examCollection, examCollectionLoading, examCollectionError] =
    useCollection(collection(db, `users/${user.uid}/exams`), {
      snapshotListenOptions: { includeMetadataChanges: false },
    });

  const cutoffDate = new Date("2024-04-25");

  return (
    <div className="container">
      <Space wrap>
        {examCollectionError && (
          <Card>
            <p>
              Es besteht ein Problem mit dem Browser-Cache: Wir haben ein Update
              durchgeführt, der Browser hat jedoch eine ältere Version im
              sogenannten Cache zwischengespeichert. Dies kann zu Anzeigefehlern
              oder veralteten Daten führen. Um das Problem zu beheben, empfehlen
              wir den Cache Ihres Browsers zu leeren. Eine detaillierte
              Anleitung für die gängigsten Browser (Firefox, Chrome, Safari,
              Edge etc.) finden Sie{" "}
              <a
                href="https://www.ionos.de/digitalguide/server/konfiguration/so-leeren-sie-ihren-cache/"
                target="_blank"
                rel="noopener noreferrer"
              >
                hier
              </a>
              . Nachdem Sie den Cache geleert haben, laden Sie die Seite neu.
              Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an
              carina@zengrade.com.
            </p>
          </Card>
        )}
        {examCollectionLoading && <span>Loading...</span>}
        {examCollection && examCollection.docs.length > 0 && (
          <React.Fragment>
            {examCollection.docs
              .filter((doc) => doc.data().hasOwnProperty("name"))
              .sort((a, b) => b.data().createdAt - a.data().createdAt) // Sort descending
              .map((doc) => {
                const examData = doc.data();

                // Ändere diese Zeile:
                const examDateCreatedAt = examData?.createdAt
                  ? examData?.createdAt.toDate()
                  : null;

                const examDateCreateTime = examData?.createTime
                  ? examData?.createTime.toDate()
                  : null;

                let isOldExam = examData?.createdAt
                  ? examDateCreatedAt && examDateCreatedAt < cutoffDate
                  : false;

                isOldExam =
                  examData?.createTime && !examData?.createdAt
                    ? examDateCreateTime < cutoffDate
                    : isOldExam;

                isOldExam =
                  !examData?.createTime &&
                  !examData?.createdAt &&
                  !examData?.formData
                    ? true
                    : isOldExam;

                return (
                  <Card
                    style={{ margin: 16, width: 300 }}
                    size="medium"
                    key={doc.id}
                  >
                    <Space direction="vertical">
                      <Space direction="horizontal">
                        {examData.isUploaded && (
                          <Tooltip
                            title={
                              examData?.isReady
                                ? "Korrekturvorschlag verfügbar"
                                : examData?.isUploaded
                                ? "Korrekturvorschlag in Bearbeitung"
                                : "PDF noch nicht hochgeladen"
                            }
                          >
                            <img
                              src={
                                examData?.isReady
                                  ? ZengradeLogo
                                  : ZengradeLogoDeactivated
                              }
                              alt="logo"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Tooltip>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {isOldExam ? (
                            <Tooltip title="Die Aufgabe wurde für eine alte Version von zengrade erstellt und kann nicht mehr angezeigt werden. Die Daten wurden noch nicht gelöscht. Bitte wenden Sie sich per E-Mail an uns, sollten Sie alte Ergebnisse nochmals abrufen müssen.">
                              <span
                                style={{
                                  flex: 1,
                                  marginRight: "10px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: "gray",
                                  cursor: "not-allowed",
                                }}
                              >
                                {examData.name}
                              </span>
                            </Tooltip>
                          ) : (
                            <>
                              <Tooltip
                                title={
                                  examData.name.length > 15 ? examData.name : ""
                                }
                              >
                                <Link
                                  to={`/status/${doc.id}`}
                                  style={{
                                    flex: 1,
                                    marginRight: "10px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {examData.name.length > 15
                                    ? `${examData.name.substring(0, 15)}...`
                                    : examData.name}
                                </Link>
                              </Tooltip>
                              {!examData.isUploaded && (
                                <>
                                  <Tooltip title={"PDF hochladen"}>
                                    <Button
                                      onClick={() =>
                                        navigate(`/upload/${doc.id}`)
                                      }
                                      type="secondary"
                                      shape="circle"
                                      icon={<UploadOutlined />}
                                    />
                                  </Tooltip>
                                  {examData.formData?.submission_id && (
                                    <Tooltip title="Erwartungshorizont bearbeiten">
                                      <Button
                                        onClick={() =>
                                          navigate(`/edit/${doc.id}`)
                                        }
                                        type="secondary"
                                        shape="circle"
                                        icon={<EditOutlined />}
                                        style={{ flexShrink: 0 }}
                                      />
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Erwartungshorizont löschen">
                                    <Button
                                      onClick={() =>
                                        navigate(`/delete/${doc.id}`)
                                      }
                                      type="secondary"
                                      shape="circle"
                                      icon={<DeleteOutlined />}
                                      style={{ flexShrink: 0 }}
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </Space>
                      <small>
                        {examData.createdAt
                          .toDate()
                          .toLocaleDateString("de-DE")}
                      </small>
                    </Space>
                  </Card>
                );
              })}
          </React.Fragment>
        )}
      </Space>
      <Alert
        message={
          <div>
            <h3>Herzlich Willkommen bei zengrade!</h3>
            <p>
              Sollten Sie diesen Account mit einer privaten E-Mail-Adresse
              erstellt haben, bitten wir Sie, einen Account mit Ihrer
              dienstlichen E-Mail-Adresse zu erstellen. Wir versenden
              Zugangslinks, die nur in den hochverschlüsselten, sicheren
              Schulnetzwerk-Konten sicher ankommen und in Ihren zengrade-Account
              führen sollen.{" "}
              <a
                href="https://zengrade.notion.site/Nutzungsbedingungen-1d0dd627d65e423e90b14fb27a3ea75a"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nutzungsbedingungen
              </a>
            </p>
            <h4>
              Bevor Sie Ihre Arbeit mit zengrade beginnen, hier ein paar
              wichtige technische Hinweise:
            </h4>
            <ul>
              <li>
                <strong>Browser-Kompatibilität:</strong> Damit alle Funktionen
                der Web-Applikation ausgeführt werden können, benötigen Sie
                aktuelle Browser-Versionen Chrome ≥92, Edge ≥92, Safari ≥15.4. Bitte beachten Sie, dass zengrade keine mobilen
                Geräte unterstützt. Für eine optimale Benutzerfreundlichkeit
                empfehlen wir die Nutzung auf einem Laptop oder PC mit einer
                Maus.
              </li>
              <li>
                Sie können bereits ohne ein abgeschlossenes Abonnement einen
                Erwartungshorizont anlegen, um sich einen Eindruck von den
                Fähigkeiten von zengrade und den Rückmeldungen, die es bieten
                kann, zu verschaffen. Bitte beachten Sie jedoch, dass zum
                Hochladen von PDF-Dokumenten zenpoints benötigt werden, für die
                ein Abonnement erforderlich ist.
              </li>
            </ul>
            <h4>Eingewöhnungszeit beachten:</h4>
            <ul>
              <li>
                Erst nach mindestens drei Korrekturen mit zengrade verspüren Sie
                eine signifikante Zeitersparnis, da sowohl Sie als auch Ihre
                Schülerinnen und Schüler eine Eingewöhnungszeit benötigen. Wir
                haben die Veröffentlichung unserer Software zu diesem Zeitpunkt
                gewählt, damit Sie ausreichend Zeit haben, Ihre neuen
                Lerngruppen an zengrade zu gewöhnen, um in der ersten
                Klassenarbeits- und Klausurenphase des Schuljahres Entlastung zu
                verspüren.{" "}
                <strong>
                  Starten Sie bitte nicht mit Texten aus Prüfungen. Wir
                  empfehlen, Hausaufgaben und Übungstexte schreiben zu lassen,
                  mit denen Sie unsere Software testen.
                </strong>
              </li>
            </ul>
            <h4>Wichtige Hinweise zum Ausfüllen der Schreibbögen:</h4>
            <ul>
              <li>Verwenden Sie nur die Vorderseite von Lineatur 27.</li>
              <li>Schrift bitte nur in jede zweite Zeile in blau.</li>
              <li>
                <h4>
                  Bitte testen Sie zengrade nur mit echten Schülerhandschriften
                  aus dem Schulalltag. Verschnörkelte, untypische Handschriften
                  oder Fantasiewörter können nicht erkannt werden.
                </h4>
              </li>
              <li>
                Schülernamen dürfen, wenn überhaupt, nur zart mit Bleistift auf
                der Rückseite vermerkt werden und dürfen nicht in zengrade
                hochgeladen werden.{" "}
                <a
                  href="https://www.youtube.com/watch?v=y9XTEAN-okQ&list=PLExbu5e54Scmw82BcXLsW9pFbP3cj6uqH&index=1&t=1s"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Erklärvideo
                </a>
              </li>
            </ul>
            <h4>Für das Scannen beachten Sie bitte:</h4>
            <ul>
              <li>Scannen Sie in Farbe im Hochformat A4 mit 300 DPI.</li>
              <li>
                Erstellen Sie ein PDF-Dokument und überprüfen Sie, ob alle
                Seiten in der richtigen Reihenfolge und nicht gedreht sind,
                bevor Sie es hochladen.
              </li>
            </ul>
            <p>
              Alle Erklärvideos zur Arbeit mit zengrade finden Sie unter
              folgendem Link:{" "}
              <a
                href="https://www.youtube.com/watch?v=y9XTEAN-okQ&list=PLExbu5e54Scmw82BcXLsW9pFbP3cj6uqH"
                target="_blank"
                rel="noopener noreferrer"
              >
                Erklärvideos
              </a>
            </p>
            <p>
              Bei Fragen zu diesen Schritten stehen wir Ihnen gerne jederzeit
              zur Verfügung. Kontaktieren Sie uns einfach unter{" "}
              <a href="mailto:info@zengrade.com">info@zengrade.com</a>. Wir
              freuen uns auf Ihre Arbeit mit zengrade und wünschen Ihnen viel
              Erfolg!
            </p>
          </div>
        }
        type="warning"
        style={{ width: "50%" }}
      />
    </div>
  );
};

export default ViewExamsPage;
