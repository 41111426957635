import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Table, Button, Space, message, Popconfirm } from "antd";
import db from "../utils/db";
import functions from "../utils/functions";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  writeBatch,
  setDoc,
  updateDoc,
  query,
  arrayRemove,
  deleteField,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "../App";
import { httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  listAll,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import storage from "../utils/storage";
import pLimit from "p-limit";

import { useDocumentData, useCollection } from "react-firebase-hooks/firestore";

import "./AdminPage.css";

const AdminPage = () => {
  let navigate = useNavigate();
  let { user } = useAuth();
  let { userIdProp, examIdProp } = useParams();

  const [results, setResults] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});

  const setLoading = (key, value) => {
    setLoadingStates((prev) => ({ ...prev, [key]: value }));
  };

  const [examData, examDataLoading, examDataError] = useDocumentData(
    doc(db, `users/${userIdProp}/exams`, examIdProp),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [userData, userLoading, userDataError] = useDocumentData(
    doc(db, `users`, userIdProp),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [
    subscriptionCollection,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionLoading,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionError,
  ] = useCollection(
    query(collection(db, `customers/${userIdProp}/subscriptions`)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [serverLoadingStates, setServerLoadingStates] = useState({});

  useEffect(() => {
    if (examData?.adminActions?.cloneExam) {
      setServerLoadingStates((prevState) => ({
        ...prevState,
        cloneExam: examData.adminActions.cloneExam === "loading",
      }));
    }
  }, [examData?.adminActions?.cloneExam]);

  const handleReject = async () => {
    setLoading("reject", true);
    const examRef = doc(db, `users/${userIdProp}/exams`, examIdProp);

    try {
      await updateDoc(examRef, {
        isUploaded: false,
        selectedPages: deleteField(),
        processing: false,
        processed: false,
      });

      // Refund credits to the user
      const userRef = doc(db, "users", userIdProp);

      const hasActiveSubscription =
        subscriptionCollection &&
        subscriptionCollection.docs.length > 0 &&
        subscriptionCollection.docs.some(
          (doc) => doc.data().status === "active"
        );

      if (hasActiveSubscription) {
        // Add to subscription credits if active subscription
        await updateDoc(userRef, {
          subscriptionCredits: examData.numPages + userData.subscriptionCredits,
        });
      } else {
        // Add to permanent credits if no active subscription
        await updateDoc(userRef, {
          permanentCredits: examData.numPages + userData.permanentCredits,
        });
      }
      message.success(
        "Prüfung erfolgreich abgewiesen, Upload wieder freigegeben und zenpoints gutgeschrieben"
      );
    } catch (error) {
      message.error("Fehler beim Abweisen der Prüfung");
    } finally {
      setLoading("reject", false);
    }
  };

  useEffect(() => {
    if (
      user.uid !== "uQwUUlHweNhJo4AIRphEgdv6Hqw1" &&
      user.uid !== "6fozyj8RQJO33Q8vWXQLMbYUzct2" &&
      user.uid !== "AoWgLdQMvSfFkqPms5P1fA9UZvZ2"
    ) {
      navigate("/view/");
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      // const querySnapshot = await getDocs(
      //   collection(db, `users/${userIdProp}/exams/${examIdProp}/submissions`)
      // );
      // const fetchedResults = [];
      // querySnapshot.forEach((doc) => {
      //   fetchedResults.push({ id: doc.id, data: doc.data() });
      // });
      // // Sort fetchedResults based on the numeric part of the id
      // fetchedResults.sort((a, b) => a.submissionId - b.submissionId);

      // setResults(fetchedResults);

      const fetchedResults = [];

      const taskFolderRef = ref(
        storage,
        `users/${userIdProp}/exams/${examIdProp}/tasks`
      );
      const listResult = await listAll(taskFolderRef);
      for (const item of listResult.items) {
        if (item.name.endsWith(".jpg")) {
          fetchedResults.push({ id: item.name.replace("_task1.jpg", "") });
        }
      }

      fetchedResults.sort((a, b) => {
        const numA = parseInt(a.id.replace("submission", ""), 10);
        const numB = parseInt(b.id.replace("submission", ""), 10);
        return numA - numB;
      });

      // console.log(JSON.stringify(fetchedResults, null, 2));

      setResults(fetchedResults);
    };
    fetchData();
  }, [userIdProp, examIdProp]);

  const deleteCollection = async (collectionRef) => {
    const snapshot = await getDocs(collectionRef);

    for (const docSnapshot of snapshot.docs) {
      // Recursively delete subcollections
      const subcollections = await docSnapshot.ref.listCollections();
      for (const subcollection of subcollections) {
        await deleteCollection(subcollection);
      }

      // Delete the document itself
      await deleteDoc(docSnapshot.ref);
    }
  };

  const handleDeleteAnnotations = async (submissionId) => {
    setLoading(`deleteAnnotations_${submissionId}`, true);

    const batch = writeBatch(db);

    // Check if the submission document exists
    const submissionDocRef = doc(
      db,
      `users/${userIdProp}/exams/${examIdProp}/submissions/${submissionId}`
    );
    const submissionDocSnapshot = await getDoc(submissionDocRef);
    if (!submissionDocSnapshot.exists()) {
      message.error("Submission document does not exist.");
      setLoading(`deleteAnnotations_${submissionId}`, false);
      return;
    }

    // Delete annotations if they exist
    const annotationsCollectionRef = collection(
      db,
      `users/${userIdProp}/exams/${examIdProp}/submissions/${submissionId}/annotations`
    );
    const annotationsSnapshot = await getDocs(annotationsCollectionRef);
    if (!annotationsSnapshot.empty) {
      annotationsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
    }

    // Delete initialAnnotations if they exist
    const initialAnnotationsCollectionRef = collection(
      db,
      `users/${userIdProp}/exams/${examIdProp}/submissions/${submissionId}/initialAnnotations`
    );
    const initialAnnotationsSnapshot = await getDocs(
      initialAnnotationsCollectionRef
    );
    if (!initialAnnotationsSnapshot.empty) {
      initialAnnotationsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
    }

    batch.update(submissionDocRef, { successfullySetAnnotations: false });

    await batch.commit();
    // Optionally, refresh the results or provide feedback to the user
    setLoading(`deleteAnnotations_${submissionId}`, false);
  };

  const handleCloneExam = async () => {
    const cloneExamFunction = httpsCallable(functions, "cloneExam");

    message.info("Klonen der Prüfung gestartet...");

    try {
      await cloneExamFunction({
        userId_from: userIdProp,
        userId_to: user.uid,
        examId: examIdProp,
      });

      message.success({
        content: (
          <span>
            Prüfung erfolgreich geklont nach
            <a
              href={`http://app.zengrade.com/grade/${examIdProp}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              http://app.zengrade.com/grade/{examIdProp}
            </a>
            bzw
            <a
              href={`http://app.zengrade.com/approve/${user.uid}/${examIdProp}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              http://app.zengrade.com/approve/{user.uid}/{examIdProp}
            </a>
          </span>
        ),
        duration: 60,
        onClose: () => console.log("Message closed"),
      });
    } catch (err) {
      message.error("Fehler beim Klonen der Prüfung: " + err.message);
    }
  };

  const handleSuggestGrades = async (submissionId) => {
    setLoading(`suggestGrades_${submissionId}`, true);

    const suggestGradesFunction = httpsCallable(
      functions,
      "suggestGradesFunctionSecondGen"
    );

    return suggestGradesFunction({
      examId: examIdProp,
      userId: userIdProp,
      submissionId: submissionId,
      taskId: "task1", // Adjust the taskId as necessary
    })
      .then(() => {
        message.success(
          "Noten erfolgreich vorgeschlagen (es kann ein paar Sekunden dauern, bis sie erscheinen)"
        );
      })
      .catch((err) => {
        message.error("Fehler bei der Notenvorschlagsfunktion:", err);
      })
      .finally(() => {
        setLoading(`suggestGrades_${submissionId}`, false);
      });
  };

  const handleDeleteExam = async () => {
    setLoading("deleteExam", true);

    results.forEach(async (submission) => {
      await handleDeleteAnnotations(submission.id);
      await handleDeleteSpecificJiixFile(submission.id);
    });

    message.success("Prüfung und alle zugehörigen Daten wurden gelöscht.");
    setLoading("deleteExam", false);
  };

  const handleSetTooManyDifferences = async (submissionId) => {
    setLoading(`setTooManyDifferences_${submissionId}`, true);

    const submissionDocRef = doc(
      db,
      `users/${userIdProp}/exams/${examIdProp}/submissions/${submissionId}`
    );

    await setDoc(
      submissionDocRef,
      { excludeSubmission: true },
      { merge: true }
    );
    message.success(
      "Hinweis darauf setzen, dass nicht verarbeitet werden konnte)"
    );
    setLoading(`setTooManyDifferences_${submissionId}`, false);
  };

  const handleDeleteSpecificJiixFile = async (submissionId) => {
    setLoading(`deleteSpecificJiixFile_${submissionId}`, true);
    const storage = getStorage();
    const jiixFileRef = ref(
      storage,
      `users/${userIdProp}/exams/${examIdProp}/tasks/${submissionId}_task1.jiix`
    );

    try {
      // Check if the file exists
      await getMetadata(jiixFileRef);

      // If the file exists, proceed to delete it
      await deleteObject(jiixFileRef);
      await handleDeleteAnnotations(submissionId);
      message.success(
        "Spezifische .jiix-Datei und zugehörige Annotationen wurden gelöscht."
      );
    } catch (error) {
      if (error.code === "storage/object-not-found") {
        // message.warning("Die spezifische .jiix-Datei wurde nicht gefunden.");
      } else {
        message.error(
          "Fehler beim Löschen der spezifischen .jiix-Datei:",
          error
        );
      }
    } finally {
      setLoading(`deleteSpecificJiixFile_${submissionId}`, false);
    }
  };

  const handleDeleteGradesAndAnnotations = async (
    userId,
    examId,
    submissionId
  ) => {
    setLoading(`deleteGradesAndAnnotations_${submissionId}`, true);

    const batch = writeBatch(db);

    // Update submission document to remove specified fields
    const submissionRef = doc(
      db,
      `users/${userId}/exams/${examId}/submissions/${submissionId}`
    );
    batch.update(submissionRef, {
      // "tasks.hinweise": arrayRemove(),
      // "tasks.lob": arrayRemove(),
      // "tasks.cefrLevel": arrayRemove(),
      // ratingsExplanations: arrayRemove(),
      // ratingsInitial: arrayRemove(),
      // ratings: arrayRemove(),
      gradesSuggested: false,
    });

    // Delete annotations with category === "content"
    const annotationsCollectionRef = collection(
      db,
      `users/${userId}/exams/${examId}/submissions/${submissionId}/annotations`
    );
    const annotationsSnapshot = await getDocs(annotationsCollectionRef);

    annotationsSnapshot.forEach((doc) => {
      if (doc.data().category === "content") {
        batch.delete(doc.ref);
      }
    });

    try {
      await batch.commit();
      message.success(
        "Punkte und ggf. Korrekturzeichen Inhalt wurden gelöscht."
      );
    } catch (error) {
      message.error("Fehler beim Löschen der Punkte und Korrekturzeichen.");
    } finally {
      setLoading(`deleteGradesAndAnnotations_${submissionId}`, false);
    }
  };

  const handleCreateAnnotations = async (submissionId) => {
    setLoading(`createAnnotations_${submissionId}`, true);

    const submissionDocRef = doc(
      db,
      `users/${userIdProp}/exams/${examIdProp}/submissions/${submissionId}`
    );

    const submissionDocSnapshot = await getDoc(submissionDocRef);
    if (submissionDocSnapshot.exists()) {
      await updateDoc(submissionDocRef, {
        successfullySetAnnotations: deleteField(),
      });
    }

    const getAnnotationFunction = httpsCallable(
      functions,
      "getAnnotationFunctionSecondGen"
    );

    return getAnnotationFunction({
      userId: userIdProp,
      examId: examIdProp,
      submissionId: submissionId,
      taskId: "task1", // Adjust the taskId as necessary
    })
      .then(() => {
        message.success(
          "Annotationen erfolgreich erstellt! Sie können nun die Annotationen überprüfen und ggf. anpassen."
        );
      })
      .catch((err) => {
        message.error({
          content: "Fehler beim Ermitteln der Annotationen",
          duration: 5,
        });
      })
      .finally(() => {
        setLoading(`createAnnotations_${submissionId}`, false);
      });
  };

  const handleRunHandwritingDetection = async (
    submissionId,
    runAnnotations
  ) => {
    setLoading(`runHandwritingDetectionWithAnnotations_${submissionId}`, true);

    const submissionDocRef = doc(
      db,
      `users/${userIdProp}/exams/${examIdProp}/submissions/${submissionId}`
    );

    const submissionDocSnapshot = await getDoc(submissionDocRef);
    if (submissionDocSnapshot.exists()) {
      await updateDoc(submissionDocRef, {
        successfullySetAnnotations: deleteField(),
      });
    }

    const runHandwritingDetectionFunction = httpsCallable(
      functions,
      "runHandwritingDetectionForSubmission"
    );

    return runHandwritingDetectionFunction({
      userId: userIdProp,
      examId: examIdProp,
      submissionId: submissionId,
      taskId: "task1",
      runAnnotations: runAnnotations,
    })
      .then(() => {
        message.success(
          "Handschrifterkennung erfolgreich durchgeführt für " + submissionId
        );
      })
      .catch((err) => {
        message.error(
          `Fehler bei der Handschrifterkennung von ${submissionId}: ${err}`
        );
      })
      .finally(() => {
        setLoading(
          `runHandwritingDetectionWithAnnotations_${submissionId}`,
          false
        );
      });
  };

  // Inside the AdminPage component
  const columns = [
    {
      title: "Submissions",
      dataIndex: "id",
      key: "id",
      className: "align-top",
      render: (text) => <span style={{ fontWeight: "bold" }}>{text}</span>,
    },
    {
      title: "Aktionen",
      key: "actions",
      render: (text, record) => (
        <Space direction="vertical">
          {/* <Space direction="horizontal"> */}
          {/* <Popconfirm
              title="Are you sure to run handwriting detection and create annotations?"
              onConfirm={() => handleRunHandwritingDetection(record.id, true)}
            >
              <Button
                loading={
                  loadingStates[
                    `runHandwritingDetectionWithAnnotations_${record.id}`
                  ]
                }
              >
                Handschrifterkennung + Annotationen erstellen {record.id}
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure to delete handwriting and annotations?"
              onConfirm={() => handleDeleteSpecificJiixFile(record.id)}
            >
              <Button
                loading={loadingStates[`deleteSpecificJiixFile_${record.id}`]}
              >
                Handschrift und Annotationen {record.id} löschen
              </Button>
            </Popconfirm>
          </Space>
          <Popconfirm
            title="Are you sure to run handwriting detection?"
            onConfirm={() => handleRunHandwritingDetection(record.id, false)}
          >
            <Button
              loading={loadingStates[`runHandwritingDetection_${record.id}`]}
            >
              Handschrifterkennung laufen lassen {record.id}
            </Button>
          </Popconfirm> */}
          {/* <Space direction="horizontal">
            <Popconfirm
              title="Are you sure to create annotations?"
              onConfirm={() => handleCreateAnnotations(record.id)}
            >
              <Button loading={loadingStates[`createAnnotations_${record.id}`]}>
                Annotationen erstellen {record.id}
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure to delete annotations?"
              onConfirm={() => handleDeleteAnnotations(record.id)}
            >
              <Button loading={loadingStates[`deleteAnnotations_${record.id}`]}>
                Annotationen {record.id} löschen
              </Button>
            </Popconfirm>
          </Space> */}

          <Space direction="horizontal">
            <Popconfirm
              title="Are you sure to suggest grades?"
              onConfirm={() => handleSuggestGrades(record.id)}
            >
              <Button loading={loadingStates[`suggestGrades_${record.id}`]}>
                Punkte vorschlagen / ggf Korrekturzeichen Inhalt erzeugen{" "}
                {record.id}
              </Button>
            </Popconfirm>
            {/* <Popconfirm
              title="Are you sure to delete grades and annotations?"
              onConfirm={() =>
                handleDeleteGradesAndAnnotations(
                  userIdProp,
                  examIdProp,
                  record.id
                )
              }
            >
              <Button
                loading={
                  loadingStates[`deleteGradesAndAnnotations_${record.id}`]
                }
              >
                Punkte / ggf Korrekturzeichen Inhalt löschen {record.id}
              </Button>
            </Popconfirm> */}
          </Space>

          {/* <Popconfirm
            title="Are you sure to set too many differences?"
            onConfirm={() => handleSetTooManyDifferences(record.id)}
          >
            <Button
              loading={loadingStates[`setTooManyDifferences_${record.id}`]}
            >
              Hinweis darauf, dass {record.id} die Vorgaben
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  // const [examData, examDataLoading, examDataError] = useDocumentData(
  //   doc(db, `users/${userIdProp}/exams`, examIdProp),
  //   {
  //     snapshotListenOptions: { includeMetadataChanges: true },
  //   }
  // );

  const handleRunHandwritingDetectionForFirstThree = async () => {
    setLoading(`createAnnotationsForFirstThree`, true);

    const firstThree = results.slice(0, 3);
    await Promise.all(
      firstThree.map((submission) => {
        return handleRunHandwritingDetection(submission.id, true);
      })
    );

    setLoading(`createAnnotationsForFirstThree`, false);
  };

  const handleRunHandwritingDetectionForRest = async () => {
    setLoading(`createAnnotationsForRest`, true);
    const rest = results.slice(3);
    const limit = pLimit(3); // Adjust the concurrency limit as needed

    await Promise.all(
      rest.map((submission) => {
        return limit(() => handleRunHandwritingDetection(submission.id, true));
      })
    );

    setLoading(`createAnnotationsForRest`, false);
  };

  return (
    <div>
      <h1>Aktionen für ALLE Einreichungen in Prüfung {examIdProp}</h1>
      {/* <Popconfirm
        title="Are you sure to create annotations for the first three submissions?"
        onConfirm={handleRunHandwritingDetectionForFirstThree}
      >
        <Button loading={loadingStates["createAnnotationsForFirstThree"]}>
          Handschrift & Annotationen für die ersten 3 Einreichungen erstellen
          (wenn vorhanden)
        </Button>
      </Popconfirm>
      <Popconfirm
        title="Are you sure to create annotations for the rest of the submissions?"
        onConfirm={handleRunHandwritingDetectionForRest}
      >
        <Button loading={loadingStates["createAnnotationsForRest"]}>
          Handschrift & Annotationen für alle restlichen Einreichung ab 4
          erstellen (wenn vorhanden)
        </Button>
      </Popconfirm> */}
      <Popconfirm
        title="Are you sure to clone the exam?"
        onConfirm={handleCloneExam}
      >
        <Button loading={serverLoadingStates.cloneExam}>
          Prüfung klonen in eigenen Account ({user.uid})
        </Button>
      </Popconfirm>
      {/* <Popconfirm
        title="Are you sure to delete the exam?"
        onConfirm={handleDeleteExam}
      >
        <Button loading={loadingStates["deleteExam"]}>
          Handschrift und Annotationen in allen Einreichungen löschen
        </Button>
      </Popconfirm> */}

      {examData?.isUploaded === true && (
        <Popconfirm
          title="Sind Sie sicher, dass Sie diesen Upload abweisen möchten? Der Upload wird dem User wieder ermöglicht und die Punkte wieder gutgeschrieben."
          onConfirm={handleReject}
        >
          <Button loading={loadingStates["reject"]}>Abweisen</Button>
        </Popconfirm>
      )}
      <h1>Aktionen für EINZELNE Einreichungen in Prüfung {examIdProp}</h1>
      <Table
        dataSource={results}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

export default AdminPage;
