import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../App";

import storage from "../utils/storage";
import db from "../utils/db"; // Assuming you have a module to export your Firestore db instance
import { doc, setDoc, collection, query, where } from "firebase/firestore"; // Import Firestore document reference and setDoc
import { ref, uploadBytesResumable } from "firebase/storage";

import {
  useDocumentData,
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import mammoth from "mammoth";

import { useSubscription } from "../hooks/useSubscription";
import SubscriptionContent from "../components/SubscriptionContent";

import {
  Modal,
  message,
  Alert,
  Button,
  Space,
  Checkbox,
  Upload,
  Progress,
  Popconfirm,
  Input,
  Tooltip,
  Radio,
  notification,
  Card,
  List,
  Table,
} from "antd";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  UploadOutlined,
  DeleteOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import StepsComponent from "../components/StepsComponent";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const DragHandle = () => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
);

const PDFUploadPage = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const [uploadType, setUploadType] = useState("typed");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const textType = searchParams.get("textType");
    if (textType === '"pencil"') {
      setUploadType("goodnotes");
    } else if (textType === '"typed"') {
      setUploadType("typed");
    }
  }, [location]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  const [totalUploadProgress, setTotalUploadProgress] = useState(0);

  const uploadAllFiles = async () => {
    const totalSize = fileList.reduce((acc, file) => acc + file.size, 0);
    let uploadedSize = 0;

    const createProgressHandler = (fileSize) => (progress) => {
      const fileBytesUploaded = (progress.percent / 100) * fileSize;
      uploadedSize += fileBytesUploaded;
      const totalProgress = (uploadedSize / totalSize) * 100;
      setTotalUploadProgress(totalProgress);
    };

    // Reorder the fileList to put the first file at the end
    const reorderedFileList = [...fileList.slice(1), fileList[0]];

    for (let i = 0; i < reorderedFileList.length; i++) {
      const file = reorderedFileList[i];
      const fileIndex = i === reorderedFileList.length - 1 ? 0 : i + 1;
      try {
        await uploadToStorage({
          file,
          onProgress: createProgressHandler(file.size),
          index: fileIndex,
        });
      } catch (error) {
        console.error(`Error uploading ${file.name}:`, error);
        // Continue with next file even if one fails
      }
    }
  };

  let { user } = useAuth();

  const { examIdProp } = useParams();

  const [userData, userLoading, userDataError] = useDocumentData(
    doc(db, `users`, user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [
    subscriptionCollection,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionLoading,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionError,
  ] = useCollection(
    query(collection(db, `customers/${user.uid}/subscriptions`)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const hasActiveSubscription =
    subscriptionCollection &&
    subscriptionCollection.docs.length > 0 &&
    subscriptionCollection.docs.some((doc) => doc.data().status === "active");

  const uploadToStorage = async (options) => {
    const { file, onSuccess, onError, onProgress, index } = options;

    // Update Firestore document after successful upload
    const examDocRef = doc(db, `users/${user.uid}/exams`, examIdProp);
    await setDoc(
      examDocRef,
      {
        isUploaded: true,
        selectedPages: selectedPages,
        processing: true,
        textType: uploadType,
      },
      { merge: true }
    );

    try {
      const newFileName =
        uploadType === "typed"
          ? `text${index + 1}.docx`
          : `text${index + 1}.pdf`;
      const folderName = uploadType === "typed" ? "text_parts" : "pencil_parts";
      const storageRef = ref(
        storage,
        `users/${user.uid}/exams/${examIdProp}/${folderName}/${newFileName}`
      );

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          onProgress({ percent: progress });
        },
        (error) => {
          onError(error);
        },
        async () => {
          onSuccess();
        }
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      onError(error);
    }
  };

  const getFileDetails = async (file) => {
    if (uploadType === "typed") {
      // For DOCX files
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const arrayBuffer = e.target.result;
            const result = await mammoth.extractRawText({ arrayBuffer });
            const wordCount = result.value.trim().split(/\s+/).length;

            resolve({
              name: file.name,
              size: (file.size / 1024 / 1024).toFixed(2) + " MB",
              words: wordCount,
              overLimit: wordCount > 2250, // Assuming 2250 words limit
            });
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });
    } else {
      // Existing PDF logic
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
          const pdf = await pdfjs.getDocument(typedArray).promise;
          const numPages = pdf.numPages;

          resolve({
            name: file.name,
            size: (file.size / 1024 / 1024).toFixed(2) + " MB",
            pages: numPages,
            overLimit: numPages > 15,
          });
        };
        reader.readAsArrayBuffer(file);
      });
    }
  };

  const columns = [
    {
      title: "Dateiname",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Größe",
      dataIndex: "size",
      key: "size",
    },
    {
      title: uploadType === "typed" ? "Wörter (Seiten)" : "Seiten",
      key: "count",
      render: (_, record) => {
        if (uploadType === "typed") {
          const pages = Math.ceil(record.words / 150);
          return `${record.words} (${pages})`;
        }
        return record.pages;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <span style={{ color: record.overLimit ? "red" : "green" }}>
          {record.overLimit
            ? uploadType === "typed"
              ? "über 2250 Wörter"
              : "über 15 Seiten"
            : "OK"}
        </span>
      ),
    },
    {
      title: "Aktion",
      key: "action",
      render: (_, record) => (
        <Button
          type="text"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(record.name)}
        ></Button>
      ),
    },
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
  ];

  const [fileList, setFileList] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);

  const handleUpload = async ({ file, onSuccess, onError }) => {
    try {
      const details = await getFileDetails(file);

      // Check if the file exceeds the limit
      if (uploadType === "typed" && details.words > 150 * 15) {
        message.error(
          `Die Datei "${file.name}" überschreitet das Limit von 2250 Wörtern.`
        );
        onError(new Error("File exceeds word limit"));
        return;
      } else if (uploadType !== "typed" && details.pages > 15) {
        message.error(
          `Die Datei "${file.name}" überschreitet das Limit von 15 Seiten.`
        );
        onError(new Error("File exceeds page limit"));
        return;
      }

      setFileList((prevFileList) => [...prevFileList, file]);
      setFileDetails((prevDetails) => [...prevDetails, details]);
      onSuccess("ok");
    } catch (error) {
      console.error("Error processing file:", error);
      onError(error);
    }
  };
  const handleDelete = (fileName) => {
    setFileList((prevFileList) =>
      prevFileList.filter((file) => file.name !== fileName)
    );
    setFileDetails((prevDetails) =>
      prevDetails.filter((detail) => detail.name !== fileName)
    );
  };

  const props = {
    name: "file",
    multiple: true,
    accept: uploadType === "typed" ? ".docx" : ".pdf",
    customRequest: handleUpload,
    showUploadList: false, // This hides the default file list
    onChange(info) {
      const { status } = info.file;
      // You can add additional logic here if needed
    },
    onRemove: (file) => {
      handleDelete(file.name);
    },
  };
  const [selectedPages, setSelectedPages] = useState([1]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    documentFeatures: {
      // For DOCX
      underlinedTitle: false,
      onlyEssayText: false,
      maxWords: false,
      noFootnotes: false,
      // For PDF
      usedTemplate: false,
      a4Format: false,
      writtenInDesignatedAreas: false,
      notBetweenLines: false,
      largeEnoughWriting: false,
      noStudentNames: false,
    },
  });

  const onCheckboxChange = (group, e) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [group]: { ...prevValues[group], [e.target.name]: e.target.checked },
    }));
  };

  const getRelevantCheckboxes = () => {
    if (uploadType === "typed") {
      return ["underlinedTitle", "onlyEssayText", "maxWords", "noFootnotes"];
    } else {
      return [
        "usedTemplate",
        "a4Format",
        "writtenInDesignatedAreas",
        "notBetweenLines",
        "largeEnoughWriting",
        "noStudentNames",
      ];
    }
  };

  const allChecked = getRelevantCheckboxes().every(
    (key) => checkboxValues.documentFeatures[key] === true
  );

  const [confirmTexts, setConfirmTexts] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    // Setzen Sie den Upload-Fortschritt zurück, wenn das Modal geöffnet wird
    setUploadProgress(null);
  };

  const handleOk = async () => {
    if (!allChecked || !confirmTexts) {
      message.error("Bitte bestätigen Sie alle Voraussetzungen.");
      return;
    }

    setIsUploading(true);
    setTotalUploadProgress(0);

    try {
      await uploadAllFiles();
      message.success("Alle Dateien wurden erfolgreich hochgeladen.");
    } catch (error) {
      console.error("Error during upload process:", error);
      message.error("Es gab einen Fehler beim Hochladen der Dateien.");
    } finally {
      setIsUploading(false);
      setIsModalVisible(false);
      navigate(`/status/${examIdProp}`);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopyUrl = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      message.success("Link wurde in die Zwischenablage kopiert", 5);
      handleCancel();
    } catch (err) {
      message.error("Fehler beim Kopieren des Links", 5);
    }
  };

  const calculateTotalPages = () => {
    if (uploadType === "typed") {
      return Math.ceil(
        fileDetails.reduce((total, file) => total + (file.words || 0), 0) / 150
      );
    } else {
      return fileDetails.reduce((total, file) => total + (file.pages || 0), 0);
    }
  };

  const totalPages = calculateTotalPages();
  const totalCredits =
    (userData?.subscriptionCredits || 0) + (userData?.permanentCredits || 0);
  const hasEnoughCredits = totalCredits >= totalPages;

  const {
    loadingStripe,
    isSubscriptionModalVisible,
    showSubscriptionModal,
    handleSubscriptionModalCancel,
    handleSubscribe,
  } = useSubscription(user);

  if (totalCredits === 0 && !hasActiveSubscription) {
    return (
      <div style={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
        <p>
          Sie haben keine verfügbaren zenpoints, um{" "}
          {uploadType === "typed" ? "eine DOCX" : "ein PDF"} hochzuladen. Bitte
          wählen Sie ein Abonnement, um fortzufahren:
        </p>
        <SubscriptionContent onSubscribe={handleSubscribe} />
      </div>
    );
  }

  const isDisabled = totalCredits === 0;

  const uploadButton = (
    <Button disabled={isDisabled} icon={<UploadOutlined />}>
      {uploadType === "typed" ? "DOCX" : "PDF"} auswählen
    </Button>
  );

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(fileList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFileList(items);

    const detailItems = Array.from(fileDetails);
    const [reorderedDetailItem] = detailItems.splice(result.source.index, 1);
    detailItems.splice(result.destination.index, 0, reorderedDetailItem);

    setFileDetails(detailItems);
  };

  const handleUploadTypeChange = (e) => {
    setUploadType(e.target.value);
    // Reset file-related states
    setFileList([]);
    setFileDetails([]);
    setSelectedPages([1]);
  };

  return (
    <>
      <StepsComponent step={1} />
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "50px auto 20px",
        }}
      >
        <Radio.Group
          value={uploadType}
          onChange={handleUploadTypeChange}
          buttonStyle="solid"
        >
          <Radio.Button value="typed">Getippter Text</Radio.Button>
          <Radio.Button value="goodnotes">GoodNotes Schrift</Radio.Button>
        </Radio.Group>
      </div> */}

      <div style={{ marginBottom: "100px" }}>
        <Modal
          title={`zengrade Voraussetzungen für ${
            uploadType === "typed" ? "DOCX" : "PDF"
          }-Verarbeitung`}
          open={isModalVisible}
          maskClosable={false}
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Popconfirm
              title={
                <>
                  <div style={{ width: "300px" }}>
                    Um das {uploadType === "typed" ? "DOCX" : "PDF"} zu einem
                    späteren Zeitpunkt hochzuladen, sichern Sie sich folgenden
                    Link:
                  </div>
                  <br />
                  <Input
                    size="small"
                    width={"100%"}
                    value={`https://app.zengrade.com/upload/${examIdProp}`}
                    readOnly
                  />
                </>
              }
              onConfirm={() =>
                handleCopyUrl(`https://app.zengrade.com/upload/${examIdProp}`)
              }
              onCancel={handleCancel}
              okText="Kopieren"
              cancelText="Fortfahren mit Abbrechen"
            >
              <Button>Abbrechen</Button>
            </Popconfirm>,
            <Button
              key="submit"
              type="primary"
              disabled={!allChecked || isUploading || !confirmTexts}
              onClick={handleOk}
              loading={isUploading}
            >
              {isUploading
                ? "Uploading..."
                : uploadType === "typed"
                ? "DOCX Upload starten"
                : "PDF Upload starten"}
            </Button>,
          ]}
          bodyStyle={{ maxHeight: "60vh", overflowY: "auto" }}
        >
          <Alert
            message={`Damit zengrade Ihre ${
              uploadType === "typed" ? "DOCX" : "PDF"
            }-Datei lesen kann, bitten wir Sie, die folgenden Voraussetzungen zu bestätigen:`}
            type="warning"
            showIcon
            style={{ marginBottom: "20px" }}
          />

          <div>
            <p>
              <b>Merkmale Dokument(e):</b>
            </p>
            {uploadType === "typed" ? (
              <>
                <Checkbox
                  name="underlinedTitle"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  Die Überschrift ist unterstrichen
                </Checkbox>
                <br />
                <Checkbox
                  name="onlyEssayText"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  Nach der Überschrift folgt nur der Aufsatztext
                </Checkbox>
                <br />
                <Checkbox
                  name="maxWords"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  Höchstens 2.250 Wörter
                </Checkbox>
                <br />
                <Checkbox
                  name="noFootnotes"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  Keine Fußnoten
                </Checkbox>
              </>
            ) : (
              <>
                <Checkbox
                  name="usedTemplate"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  Das zengrade-Template wurde genutzt
                </Checkbox>
                <br />
                <Checkbox
                  name="a4Format"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  A4-Dokument
                </Checkbox>
                <br />
                <Checkbox
                  name="writtenInDesignatedAreas"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  In den dafür ausgezeichneten Bereichen geschrieben
                </Checkbox>
                <br />
                <Checkbox
                  name="notBetweenLines"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  Nicht zwischen die Zeilen geschrieben
                </Checkbox>
                <br />
                <Checkbox
                  name="largeEnoughWriting"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  Ausreichend groß geschrieben (Schrift füllt Zeilen aus)
                </Checkbox>
                <br />
                <Checkbox
                  name="noStudentNames"
                  onChange={(e) => onCheckboxChange("documentFeatures", e)}
                >
                  Keine Schülernamen auf den Seiten (nur
                  Kennung/Zahl/Chiffre/Abkürzung/Initialen erlaubt)
                </Checkbox>
              </>
            )}
          </div>
          <br />
          <small>
            Hinweis: Die Überschrift spielt für die Bewertung keine Rolle und
            wird ignoriert. Alles vor der Überschrift sowie Kopf- und Fußzeilen
            werden ebenfalls ignoriert. Strukturelemente wie Paragraphen /
            explizite Zeilenumbrüche werden übernommen.
          </small>
          <p>
            <b>Merkmale Text(e):</b>
          </p>
          <Checkbox
            checked={confirmTexts}
            onChange={(e) => setConfirmTexts(e.target.checked)}
          >
            Ich bestätige, dass ich alle Texte überprüft habe und sie den
            Anforderungen entsprechen.
          </Checkbox>
        </Modal>

        {/* Add margin to avoid overlap with the fixed area */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          {/* <Card title="Erklärvideo zur Benutzung des PDF-Uploads">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/iNtKbPNY4g4?si=OacMb74bGrM7pDhA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Card> */}
          {/* {!file && (
            <Alert
              message={`Bitte wählen Sie ein PDF aus, um fortzufahren.`}
              type="info"
              showIcon
              style={{ marginBottom: "20px" }}
            />
          )} */}

          {fileList.length > 0 && (
            <>
              <Alert
                message={(() => {
                  const cost = totalPages || 0;
                  return `Die Verarbeitung kostet ${cost} zenpoint(s). ${
                    hasEnoughCredits
                      ? `Sie haben eine ausreichende Anzahl an zenpoints.`
                      : `Sie haben keine ausreichende Anzahl an zenpoints. Bitte laden Sie die zenpoints auf.`
                  }`;
                })()}
                type={hasEnoughCredits ? "info" : "error"}
                style={{ marginBottom: "20px" }}
                showIcon
              />
            </>
          )}

          <Upload {...props}>
            {isDisabled ? (
              <Tooltip title="Sie haben keine verfügbaren zenpoints. Bitte laden Sie Ihr Konto auf.">
                {uploadButton}
              </Tooltip>
            ) : (
              uploadButton
            )}
          </Upload>

          {fileDetails.length > 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Table
                components={{
                  body: {
                    wrapper: (props) => (
                      <Droppable droppableId="table">
                        {(provided) => (
                          <tbody {...props} ref={provided.innerRef}>
                            {props.children}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    ),
                    row: (props) => (
                      <Draggable
                        draggableId={props["data-row-key"].toString()}
                        index={props.index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            {...props}
                            style={{
                              ...props.style,
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging
                                ? "rgba(255, 255, 255, 0.5)"
                                : "white",
                            }}
                          />
                        )}
                      </Draggable>
                    ),
                  },
                }}
                dataSource={fileDetails}
                columns={columns}
                rowKey="name"
                pagination={false}
                style={{ marginTop: "20px" }}
              />
            </DragDropContext>
          )}
        </div>

        {fileList.length > 0 && (
          <>
            {fileList.length > 0 && (
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  textAlign: "center",
                  borderTop: "1px solid #ddd",
                }}
              >
                <Button onClick={showModal} type="primary">
                  Continue with {fileList.length} file(s)
                </Button>
              </div>
            )}

            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "#f0f0f0",
                padding: "10px",
                textAlign: "center",
                borderTop: "1px solid #ddd",
              }}
            >
              <Space>
                {!hasEnoughCredits ? (
                  <Tooltip
                    title="keine ausreichende Anzahl an zenpoints"
                    placement="top"
                  >
                    <Button onClick={showModal} disabled>
                      Auswahl beenden & fortfahren
                    </Button>
                  </Tooltip>
                ) : (
                  <Button onClick={showModal} type="primary">
                    Auswahl beenden & fortfahren
                  </Button>
                )}
              </Space>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PDFUploadPage;
