import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { isFirefox } from "react-device-detect";

import { useAuth } from "../App";

import db from "../utils/db";
import storage from "../utils/storage";
import functions from "../utils/functions";

import { httpsCallable } from "firebase/functions";

import {
  collection,
  doc,
  onSnapshot,
  query,
  addDoc,
  where,
  deleteDoc,
  setDoc,
  serverTimestamp,
  writeBatch,
  getDocs,
  updateDoc,
  deleteField,
} from "firebase/firestore";

import { useDocumentData } from "react-firebase-hooks/firestore";

import {
  getBytes,
  getDownloadURL,
  ref,
  listAll,
  getStorage,
  getMetadata,
  deleteObject,
} from "firebase/storage";

import HandwritingCanvas from "../components/HandwritingCanvas";
import GradeOverviewComponent from "../components/GradeOverviewComponent";

import {
  Button,
  Input,
  Space,
  Layout,
  Typography,
  message,
  Dropdown,
  Menu,
  Modal,
  Checkbox,
  Spin,
  Popconfirm,
  Alert,
  Tabs,
  Tooltip,
  Divider,
  Radio,
  Collapse,
} from "antd";

import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownOutlined,
  WarningOutlined,
  PrinterOutlined,
  CopyOutlined,
  MailOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import PointComponent from "../components/PointComponent";

import { extractImageDetails, extractAllImageDetails } from "../utils/common";

import { defaultCorrectionMarks } from "../components/CorrectionMarkDefinition";

import "./GradeExamPage.css";

const { Content, Footer } = Layout;
const { Text } = Typography;

const MAX_CRITERIA = 50;

const { Panel } = Collapse;

const GradeExamPage = () => {
  const location = useLocation();

  let navigate = useNavigate();
  const [imageList, setImageList] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});

  let { user } = useAuth();

  let { userIdProp, examIdProp } = useParams();

  let userId = userIdProp ? userIdProp : user.uid;

  useEffect(() => {
    // Check if the user is one of the specified users, otherwise redirect
    if (
      userIdProp &&
      userIdProp !== user.uid &&
      user.uid !== "uQwUUlHweNhJo4AIRphEgdv6Hqw1" &&
      user.uid !== "6fozyj8RQJO33Q8vWXQLMbYUzct2" &&
      user.uid !== "AoWgLdQMvSfFkqPms5P1fA9UZvZ2"
    ) {
      navigate("/view/");
    }
  }, [user, navigate]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageDetails, setImageDetails] = useState([]);
  const [submissionId, setSubmissionId] = useState("submission1");
  const [taskNum, setTaskNum] = useState("task1");

  const [visualizeLines, setVisualizeLines] = useState(true);
  const [visualizeCorrectionText, setVisualizeCorrectionText] = useState(true);
  const [visualizeBBs, setVisualizeBBs] = useState(true);
  const [visualizeDebugView, setVisualizeDebugView] = useState(false);

  // State für das Modal und die Checkboxen
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Refs für die Checkboxen
  const includeGradingKeyRef = useRef(true);
  const includeCorrectionMarksRef = useRef(true);

  const [showLanguagePartialGrades, setShowLanguagePartialGrades] =
    useState(false);
  const [
    showLanguagePartialGradesExplanations,
    setShowLanguagePartialGradesExplanations,
  ] = useState(false);
  const [showLanguageOverallGrade, setShowLanguageOverallGrade] =
    useState(false);
  const [showLanguageImprovementTips, setShowLanguageImprovementTips] =
    useState(false);
  const [showLanguagePraise, setShowLanguagePraise] = useState(false);

  const [showContentPartialGrades, setShowContentPartialGrades] =
    useState(false);
  const [
    showContentCorrectionMarkExplanations,
    setShowContentCorrectionMarkExplanations,
  ] = useState(false);
  const [showContentOverallGrade, setShowContentOverallGrade] = useState(false);
  const [showContentImprovementTips, setShowContentImprovementTips] =
    useState(false);
  const [showContentPraise, setShowContentPraise] = useState(false);

  const [overallGradeDisplay, setOverallGradeDisplay] = useState(null);

  // const [showOverallGrade, setShowOverallGrade] = useState(false);

  const [showHandwritingWithAllMarks, setShowHandwritingWithAllMarks] =
    useState(true);
  const [underlineLanguageErrorsOnly, setUnderlineLanguageErrorsOnly] =
    useState(false);

  const [examData, examDataLoading, examDataError] = useDocumentData(
    doc(db, `users/${userId}/exams`, examIdProp),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  useEffect(() => {
    if (
      examData &&
      !examDataLoading &&
      !examDataError &&
      !examData.isReady &&
      !window.location.href.includes("/approve/")
    ) {
      navigate(`/status/${examIdProp}`);
    }
  }, [examData, examDataLoading, examDataError, navigate, examIdProp]);

  const [submissionData, loadingSubmissionData, errorSubmissionData] =
    useDocumentData(
      doc(db, `users/${userId}/exams/${examIdProp}/submissions`, submissionId),
      {
        snapshotListenOptions: { includeMetadataChanges: false },
      }
    );

  const handlePreviousImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < imageList.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleNextSubmission = () => {
    const currentDetail = imageDetails[currentIndex];
    const nextIndex = imageDetails.findIndex(
      (detail, index) =>
        index > currentIndex &&
        detail.taskNum === currentDetail.taskNum &&
        detail.submissionId !== currentDetail.submissionId
    );
    if (nextIndex !== -1) {
      setCurrentIndex(nextIndex);
    }
  };

  const handlePreviousSubmission = () => {
    const currentDetail = imageDetails[currentIndex];
    const prevIndex = [...imageDetails]
      .reverse()
      .findIndex(
        (detail, index) =>
          imageDetails.length - 1 - index < currentIndex &&
          detail.taskNum === currentDetail.taskNum &&
          detail.submissionId !== currentDetail.submissionId
      );
    if (prevIndex !== -1) {
      setCurrentIndex(imageDetails.length - 1 - prevIndex);
    }
  };

  const onChangeRatingData = async (criterionId, updateData) => {
    const ratingRef = doc(
      db,
      `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/ratings/${criterionId}`
    );

    await setDoc(ratingRef, updateData, { merge: true });
  };

  useEffect(() => {
    const details = extractAllImageDetails(imageList);
    setImageDetails(details);
  }, [imageList]);

  const renderDropdownMenu = () => {
    // Group image details by submissionId
    const groupedDetails = imageDetails.reduce((acc, detail) => {
      const { submissionId, taskNum } = detail;
      if (!acc[submissionId]) {
        acc[submissionId] = [];
      }
      acc[submissionId].push(taskNum);
      return acc;
    }, {});

    // Determine the key of the currently selected item
    const currentKey = imageDetails[currentIndex]
      ? `${imageDetails[currentIndex].submissionId}_${imageDetails[currentIndex].taskNum}`
      : null;

    return (
      <Menu selectedKeys={[currentKey]}>
        {Object.entries(groupedDetails).map(([submissionId, taskNums]) => (
          <Menu.ItemGroup
            key={submissionId}
            // title={`Einreichung ${submissionId.replace("submission", "")}`}
          >
            {taskNums.map((taskNum) => {
              const key = `${submissionId}_${taskNum}`;
              return (
                <Menu.Item
                  key={key}
                  onClick={() =>
                    setCurrentIndex(
                      imageDetails.findIndex(
                        (detail) =>
                          `${detail.submissionId}_${detail.taskNum}` === key
                      )
                    )
                  }
                  style={{ paddingLeft: "24px" }} // Indent the subitems for better visual grouping
                >
                  {`Aufsatz ${submissionId.replace("submission", "")}`}
                </Menu.Item>
              );
            })}
          </Menu.ItemGroup>
        ))}
      </Menu>
    );
  };

  useEffect(() => {
    const taskFolderRef = ref(
      storage,
      `users/${userId}/exams/${examIdProp}/tasks`
    );

    listAll(taskFolderRef)
      .then((res) => {
        const sortedItems = res.items
          .filter((item) => item.name.endsWith(".jpg"))
          .map((item) => {
            const match = item.name.match(/submission(\d+)_task(\d+)\.jpg/);
            return {
              name: item.name,
              submissionId: match ? parseInt(match[1], 10) : 0,
              taskNum: match ? parseInt(match[2], 10) : 0,
              ref: item,
            };
          })
          .sort((a, b) => {
            if (a.submissionId !== b.submissionId) {
              return a.submissionId - b.submissionId;
            }
            return a.taskNum - b.taskNum;
          });

        const downloadUrlsPromises = sortedItems.map((item) =>
          getDownloadURL(item.ref)
        );

        Promise.all(downloadUrlsPromises)
          .then((urls) => {
            setImageList(urls);
            if (urls.length > 0) {
              const { submissionId, taskNum } = extractImageDetails(urls[0]);
              setSubmissionId(submissionId);
              setTaskNum(taskNum);
            }
          })
          .catch((error) => {
            console.error("Error getting download URLs: ", error);
          });
      })
      .catch((error) => {
        console.error("Error listing task files: ", error);
      });
  }, [examIdProp, userId]);

  useEffect(() => {
    if (imageList.length > 0 && currentIndex < imageList.length) {
      const { submissionId, taskNum } = extractImageDetails(
        imageList[currentIndex]
      );
      setSubmissionId(submissionId);
      setTaskNum(taskNum);
    }
  }, [currentIndex, imageList]);

  let taskType, linguisticCriteriaName, contentCriteriaName;
  try {
    // Determine the task type
    taskType =
      examData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]?.taskType;

    linguisticCriteriaName =
      examData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]
        ?.linguisticCriteriaName;

    contentCriteriaName =
      examData?.tasks[parseInt(taskNum.replace("task", ""), 10) - 1]
        ?.contentCriteriaName;
  } catch (error) {
    navigate(`/status/${examIdProp}`);
  }

  const setExamReady = () => {
    // Call the Cloud Function to send the email
    const sendReturnExamEmailToUserFunction = httpsCallable(
      functions,
      "sendReturnExamEmailToUser"
    );

    sendReturnExamEmailToUserFunction({
      examId: examIdProp,
      userId: userIdProp,
    })
      .then(() => {
        message.success(
          `Prüfung wurde freigegeben und ist für den User sichtbar (BCC an die Admins). Die URL ${`https://app.zengrade.com/grade/${examIdProp}`} wurde in die Zwischenablage kopiert.`
        );
        return navigator.clipboard.writeText(
          `https://app.zengrade.com/grade/${examIdProp}`
        );
      })
      .catch((error) => {
        console.error("Error in setExamReady:", error);
        message.error("Es gab einen Fehler beim Freigeben der Prüfung.");
      });
  };

  const isAnyAdminActionLoading = (adminActions) => {
    if (!adminActions) return false;
    return Object.values(adminActions).some((action) => action === "loading");
  };

  const DefaultUI = ({ imageSrc, currentIndex, leftStyle, rightStyle }) => {
    const [jiix, setJIIX] = useState("");
    const [annotations, setAnnotations] = useState([]);

    const [loadingAnnotations, setLoadingAnnotations] = useState(true);
    const [loadingJIIX, setLoadingJIIX] = useState(true);
    const [loadingHandwritingImageUrl, setLoadingHandwritingImageUrl] =
      useState(true);

    // Add the state variables and refs required by PointComponent
    const [criteria, setCriteria] = useState([]);
    const [ratings, setRatings] = useState([]);
    // const [submissionCollection, setSubmissionCollection] = useState(null);
    const [loadingSuggestedGrades, setLoadingSuggestedGrades] = useState(false);
    const [overallCommentLanguage, setOverallCommentLanguage] = useState("");
    const [overallCommentContent, setOverallCommentContent] = useState("");
    const lastSubmissionPosRef = useRef(0);

    // const [submissionData, loadingSubmissionData, errorSubmissionData] =
    //   useDocumentData(
    //     doc(
    //       db,
    //       `users/${userId}/exams/${examIdProp}/submissions`,
    //       submissionId
    //     ),
    //     {
    //       snapshotListenOptions: { includeMetadataChanges: false },
    //     }
    //   );

    const addCriterionToDB = async (criterion_type) => {
      if (criteria && criteria.length < MAX_CRITERIA) {
        const newCriterion = {
          type: criterion_type,
          description: "",
          points: 15,
          task: taskNum,
          createdAt: serverTimestamp(), // Add a timestamp field
        };

        await addDoc(
          collection(db, `users/${userId}/exams/${examIdProp}/criteria`),
          newCriterion
        );
      }
    };

    const deleteCriterion = async (criterionId) => {
      await deleteDoc(
        doc(db, `users/${userId}/exams/${examIdProp}/criteria/${criterionId}`)
      );

      // TODO: Delete all ratings for this criterion?
    };

    const changeCriterionInDB = async (criterionId, newValues) => {
      const docRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/criteria`,
        criterionId
      );

      await setDoc(docRef, newValues, { merge: true });
    };

    const changeOverallCommentContentInDB = () => {
      const docRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions/${submissionId}`
      );

      let newValues = {};
      newValues["overallCommentContent"] = overallCommentContent;

      setDoc(docRef, newValues, { merge: true });
    };

    const changeOverallCommentLanguageInDB = () => {
      const docRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions/${submissionId}`
      );

      let newValues = {};
      newValues["overallCommentLanguage"] = overallCommentLanguage;

      setDoc(docRef, newValues, { merge: true });
    };

    const onChangeOverallCommentLanguage = (value) => {
      setOverallCommentLanguage(value);
      changeOverallCommentLanguageInDB();
    };

    const onChangeOverallCommentContent = (value) => {
      setOverallCommentContent(value);
      changeOverallCommentContentInDB();
    };

    const getPercentageAchieved = (criteriaType) => {
      let filteredCriteria;
      if (criteriaType === "all") {
        const linguisticCorrectness = getPercentageAchieved("linguistic");
        const contentCorrectness = getPercentageAchieved("content");

        // Check if isRatioActive is true
        if (examData?.isRatioActive) {
          // Use the ratio to determine the overall score
          return (
            (linguisticCorrectness *
              parseInt(examData?.formData.schreibensie)) /
              100 +
            contentCorrectness *
              (1 - parseInt(examData?.formData.schreibensie) / 100)
          );
        } else {
          // Weight the scores equally
          return (linguisticCorrectness + contentCorrectness) / 2;
        }
      } else {
        filteredCriteria = criteria.filter(
          (criterion) => criterion.type === criteriaType
        );
      }

      const criteriaIds = filteredCriteria.map((criterion) => criterion.id);

      if (!submissionData || !submissionData.ratings) {
        return 0; // Return 0 or any default value when data is unavailable
      }

      const submissionRatings = submissionData.ratings;

      const relevantRatings = Object.entries(submissionRatings).filter(
        ([key]) => criteriaIds.includes(key)
      );

      const achievedPoints = relevantRatings.reduce(
        (sum, [, value]) => sum + value,
        0
      );
      const totalPoints = filteredCriteria.reduce(
        (sum, criterion) => sum + criterion.points,
        0
      );

      const percentage = (achievedPoints / totalPoints) * 100;
      return Math.round(percentage);
    };

    const createMarks = () => {
      const marks = {};
      for (let i = 0; i <= 100; i += 10) {
        marks[i] = `${i}%`;
      }
      return marks;
    };

    const suggestGrades = async () => {
      setLoadingSuggestedGrades(true);
      const suggestGradesFunction = httpsCallable(
        functions,
        "suggestGradesFunctionSecondGen"
      );

      suggestGradesFunction({
        examId: examIdProp,
        userId: userId,
        submissionId: submissionId,
        taskId: taskNum,
      })
        .then(() => {
          message.destroy();
          message.success({
            content:
              "Noten erfolgreich vorgeschlagen - klicken Sie auf Sprache oder Inhalt",
            duration: 0,
            onClick: () => message.destroy(),
          });
        })
        .catch((err) => {
          message.destroy();
          message.error({
            content:
              "Es ist ein Fehler bei der Abfrage über Microsoft Azure aufgetreten. Bitte versuchen Sie es erneut. Sollte der Fehler mehrmals auftreten, erhalten Sie eine persönliche E-Mail von uns. Danke für Ihr Verständnis.",
            duration: 0,
            onClick: () => message.destroy(),
          });
        })
        .finally(() => {
          setLoadingSuggestedGrades(false);
        });
    };

    useEffect(() => {
      // Fetch criteria
      const unsubscribeCriteria = onSnapshot(
        query(
          collection(db, `users/${userId}/exams/${examIdProp}/criteria`),
          where("task", "==", taskNum) // Filter for the current task
        ),
        (querySnapshot) => {
          const criteriaList = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            criteriaList.push({
              id: doc.id,
              ...data,
            });
          });

          // Sort the criteria list
          criteriaList.sort((a, b) => {
            if (!a.createdAt && !b.createdAt) {
              return 0;
            }
            if (!a.createdAt) {
              return -1;
            }
            if (!b.createdAt) {
              return 1;
            }
            return a.createdAt - b.createdAt;
          });

          setCriteria(criteriaList);
        }
      );

      // Fetch ratings
      const unsubscribeRatings = onSnapshot(
        collection(
          db,
          `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/ratings`
        ),
        (querySnapshot) => {
          const ratingsList = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            ratingsList.push({
              id: doc.id,
              ...data,
            });
          });

          setRatings(ratingsList);
        }
      );

      // Clean up the listeners when the component unmounts
      return () => {
        unsubscribeCriteria();
        unsubscribeRatings();
      };
    }, [userId, examIdProp, taskNum, submissionId]);

    const onChangeCheckbox = (e) => {
      if (examData) {
        const docRef = doc(db, `users/${userId}/exams`, examIdProp);
        setDoc(docRef, { isRatioActive: e.target.checked }, { merge: true });
      }
    };

    const setRatio = (value) => {
      if (examData) {
        const docRef = doc(db, `users/${userId}/exams`, examIdProp);
        setDoc(docRef, { ratio: value }, { merge: true });
      }
    };

    const onChangeCriteriaDescription = (value, id) => {
      const newCriteria = criteria.map((criterion) => {
        if (criterion.id === id) {
          return { ...criterion, description: value };
        } else {
          return criterion;
        }
      });
      setCriteria(newCriteria);
    };

    // const onChangeCriteriaRating = async (value, criterionId) => {
    //   const ratingRef = doc(
    //     db,
    //     `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/ratings/${criterionId}`
    //   );

    //   const ratingData = {
    //     points: value,
    //     updatedAt: serverTimestamp(),
    //   };

    //   await setDoc(ratingRef, ratingData, { merge: true });
    // };

    const onChangeCriteriaPoints = (value, id) => {
      const newCriteria = criteria.map((criterion) => {
        if (criterion.id === id) {
          return { ...criterion, points: value };
        } else {
          return criterion;
        }
      });
      setCriteria(newCriteria);
    };

    const isDataReadyForRendering = (submissionData) => {
      if (!submissionData) return false;

      // Prüfen, ob Admin-Actions laufen
      if (submissionData.adminActions) {
        const isLoading = Object.values(submissionData.adminActions).some(
          (actionStatus) => actionStatus === "loading"
        );
        if (isLoading) return false;
      }

      // // Prüfen, ob notwendige Daten vorhanden sind
      // const hasNecessaryData = submissionData.annotations;

      return true;
    };
    return (
      <>
        <Content style={leftStyle}>
          <img
            src={imageSrc}
            alt={`Loading...`}
            style={{ width: "100%", height: "auto" }}
          />
        </Content>
        <Content style={rightStyle}>
          <div style={{ width: "100%" }}>
            {submissionData &&
            !submissionData.excludeSubmission &&
            !loadingSubmissionData &&
            criteria &&
            submissionId &&
            isAnyAdminActionLoading(submissionData?.adminActions) ? (
              <PointComponent
                userId={userId}
                examId={examIdProp}
                taskNum={taskNum}
                linguisticCriteriaNames={linguisticCriteriaName}
                contentCriteriaNames={contentCriteriaName}
                taskType={taskType}
                criteria={criteria}
                ratings={ratings}
                submissionData={submissionData}
                suggestGrades={suggestGrades}
                loadingSuggestedGrades={loadingSuggestedGrades}
                lastSubmissionPosRef={lastSubmissionPosRef}
                getPercentageAchieved={getPercentageAchieved}
                createMarks={createMarks}
                addCriterionToDB={addCriterionToDB}
                MAX_CRITERIA={MAX_CRITERIA}
                overallCommentLanguage={overallCommentLanguage}
                onChangeOverallCommentLanguage={onChangeOverallCommentLanguage}
                changeOverallCommentLanguageInDB={
                  changeOverallCommentLanguageInDB
                }
                overallCommentContent={overallCommentContent}
                onChangeOverallCommentContent={onChangeOverallCommentContent}
                changeOverallCommentContentInDB={
                  changeOverallCommentContentInDB
                }
                examData={examData}
                onChangeCheckbox={onChangeCheckbox}
                setRatio={setRatio}
                onChangeCriteriaDescription={onChangeCriteriaDescription}
                changeCriterionInDB={changeCriterionInDB}
                onChangeRatingData={onChangeRatingData}
                onChangeCriteriaPoints={onChangeCriteriaPoints}
                deleteCriterion={deleteCriterion}
                annotations={annotations}
                message={message}
              />
            ) : (
              <div>Lade Daten... Bitte warten.</div>
            )}
          </div>
        </Content>
      </>
    );
  };

  const FreitextUI = ({ leftStyle, rightStyle }) => {
    const [jiix, setJIIX] = useState(null);
    const [annotations, setAnnotations] = useState([]);

    const [loadingAnnotations, setLoadingAnnotations] = useState(true);
    const [loadingJIIX, setLoadingJIIX] = useState(true);
    const [loadingHandwritingImageUrl, setLoadingHandwritingImageUrl] =
      useState(true);

    // Add the state variables and refs required by PointComponent
    const [criteria, setCriteria] = useState([]);
    const [ratings, setRatings] = useState([]);

    // const [submissionCollection, setSubmissionCollection] = useState(null);
    const [loadingSuggestedGrades, setLoadingSuggestedGrades] = useState(false);
    const [overallCommentLanguage, setOverallCommentLanguage] = useState("");
    const [overallCommentContent, setOverallCommentContent] = useState("");
    const lastSubmissionPosRef = useRef(0);

    const [correctionModalVisible, setCorrectionModalVisible] = useState(false);

    // const [submissionData, loadingSubmissionData, errorSubmissionData] =
    //   useDocumentData(
    //     doc(
    //       db,
    //       `users/${userId}/exams/${examIdProp}/submissions`,
    //       submissionId
    //     ),
    //     {
    //       snapshotListenOptions: { includeMetadataChanges: false },
    //     }
    //   );

    const addCriterionToDB = async (criterion_type) => {
      if (criteria && criteria.length < MAX_CRITERIA) {
        const newCriterion = {
          type: criterion_type,
          description: "",
          points: 15,
          task: taskNum,
          createdAt: serverTimestamp(), // Add a timestamp field
        };

        await addDoc(
          collection(db, `users/${userId}/exams/${examIdProp}/criteria`),
          newCriterion
        );
      }
    };

    const deleteCriterion = async (criterionId) => {
      await deleteDoc(
        doc(db, `users/${userId}/exams/${examIdProp}/criteria/${criterionId}`)
      );
    };

    const changeCriterionInDB = async (criterionId, newValues) => {
      const docRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/criteria`,
        criterionId
      );

      await setDoc(docRef, newValues, { merge: true });
    };

    const changeOverallCommentContentInDB = () => {
      const docRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions/${submissionId}`
      );

      let newValues = {};
      newValues["overallCommentContent"] = overallCommentContent;

      setDoc(docRef, newValues, { merge: true });
    };

    const changeOverallCommentLanguageInDB = () => {
      const docRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions/${submissionId}`
      );

      let newValues = {};
      newValues["overallCommentLanguage"] = overallCommentLanguage;

      setDoc(docRef, newValues, { merge: true });
    };

    const changeHinweiseInDB = async (hinweise) => {
      let tasks = submissionData.tasks || [];
      const taskIndexToUpdate = tasks.findIndex(
        (task) => task.taskNum === parseInt(taskNum.replace("task", ""), 10)
      );

      if (taskIndexToUpdate !== -1) {
        tasks[taskIndexToUpdate] = {
          ...tasks[taskIndexToUpdate],
          hinweise: hinweise,
        };
      }

      const submissionRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions`,
        submissionId
      );
      await setDoc(submissionRef, { tasks: tasks }, { merge: true });
    };

    const changeLobInDB = async (lob) => {
      let tasks = submissionData.tasks || [];
      const taskIndexToUpdate = tasks.findIndex(
        (task) => task.taskNum === parseInt(taskNum.replace("task", ""), 10)
      );

      if (taskIndexToUpdate !== -1) {
        tasks[taskIndexToUpdate] = {
          ...tasks[taskIndexToUpdate],
          lob: lob,
        };
      }

      const submissionRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions`,
        submissionId
      );
      await setDoc(submissionRef, { tasks: tasks }, { merge: true });
    };

    const changeHinweiseContentInDB = async (hinweiseContent) => {
      let tasks = submissionData.tasks || [];
      const taskIndexToUpdate = tasks.findIndex(
        (task) => task.taskNum === parseInt(taskNum.replace("task", ""), 10)
      );

      if (taskIndexToUpdate !== -1) {
        tasks[taskIndexToUpdate] = {
          ...tasks[taskIndexToUpdate],
          hinweiseContent: hinweiseContent,
        };
      }

      const submissionRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions`,
        submissionId
      );
      await setDoc(submissionRef, { tasks: tasks }, { merge: true });
    };

    const changeLobContentInDB = async (lobContent) => {
      let tasks = submissionData.tasks || [];
      const taskIndexToUpdate = tasks.findIndex(
        (task) => task.taskNum === parseInt(taskNum.replace("task", ""), 10)
      );

      if (taskIndexToUpdate !== -1) {
        tasks[taskIndexToUpdate] = {
          ...tasks[taskIndexToUpdate],
          lobContent: lobContent,
        };
      }

      const submissionRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions`,
        submissionId
      );
      await setDoc(submissionRef, { tasks: tasks }, { merge: true });
    };

    const changeAllgemeineAufgabenstellungInDB = async (
      allgemeineAufgabenstellung
    ) => {
      const updatedRatingsExplanations = {
        ...submissionData.ratingsExplanations,
        "8nz409OMDAmimz6n9H3e": allgemeineAufgabenstellung,
      };

      const submissionRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions`,
        submissionId
      );
      await setDoc(
        submissionRef,
        { ratingsExplanations: updatedRatingsExplanations },
        { merge: true }
      );
    };

    const onChangeOverallCommentLanguage = (value) => {
      setOverallCommentLanguage(value);
      changeOverallCommentLanguageInDB();
    };

    const onChangeOverallCommentContent = (value) => {
      setOverallCommentContent(value);
      changeOverallCommentContentInDB();
    };

    const getPercentageAchieved = (criteriaType) => {
      let filteredCriteria;
      if (criteriaType === "all") {
        const linguisticCorrectness = getPercentageAchieved("linguistic");
        const contentCorrectness = getPercentageAchieved("content");
        // TODO: type = "other" ???

        // Check if isRatioActive is true
        if (examData?.isRatioActive) {
          // Use the ratio to determine the overall score
          return (
            (linguisticCorrectness *
              parseInt(examData?.formData.schreibensie)) /
              100 +
            contentCorrectness *
              (1 - parseInt(examData?.formData.schreibensie) / 100)
          );
        } else {
          // Weight the scores equally
          return (linguisticCorrectness + contentCorrectness) / 2;
        }
      } else {
        filteredCriteria = criteria.filter(
          (criterion) => criterion.type === criteriaType
        );
      }

      const criteriaIds = filteredCriteria.map((criterion) => criterion.id);

      if (!submissionData || !submissionData.ratings) {
        return 0; // Return 0 or any default value when data is unavailable
      }

      const submissionRatings = submissionData.ratings;

      const relevantRatings = Object.entries(submissionRatings).filter(
        ([key]) => criteriaIds.includes(key)
      );

      const achievedPoints = relevantRatings.reduce(
        (sum, [, value]) => sum + value,
        0
      );
      const totalPoints = filteredCriteria.reduce(
        (sum, criterion) => sum + criterion.points,
        0
      );

      const percentage = (achievedPoints / totalPoints) * 100;
      return Math.round(percentage);
    };

    const createMarks = () => {
      const marks = {};
      for (let i = 0; i <= 100; i += 10) {
        marks[i] = `${i}%`;
      }
      return marks;
    };

    const suggestGrades = async () => {
      setLoadingSuggestedGrades(true);
      const suggestGradesFunction = httpsCallable(
        functions,
        "suggestGradesFunctionSecondGen"
      );

      suggestGradesFunction({
        examId: examIdProp,
        userId: userId,
        submissionId: submissionId,
        taskId: taskNum,
      })
        .then(() => {
          message.destroy();
          message.success({
            content:
              "Noten erfolgreich vorgeschlagen - klicken Sie auf Sprache oder Inhalt",
            duration: 0,
            onClick: () => message.destroy(),
          });
        })
        .catch((err) => {
          message.destroy();
          message.error({
            content:
              "Es ist ein Fehler bei der Abfrage über Microsoft Azure aufgetreten. Bitte versuchen Sie es erneut. Sollte der Fehler mehrmals auftreten, erhalten Sie eine persönliche E-Mail von uns. Danke für Ihr Verständnis.",
            duration: 0,
            onClick: () => message.destroy(),
          });
        })
        .finally(() => {
          setLoadingSuggestedGrades(false);
        });
    };

    useEffect(() => {
      // Fetch criteria
      const unsubscribeCriteria = onSnapshot(
        query(
          collection(db, `users/${userId}/exams/${examIdProp}/criteria`),
          where("task", "==", taskNum) // Filter for the current task
        ),
        (querySnapshot) => {
          const criteriaList = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            criteriaList.push({
              id: doc.id,
              ...data,
            });
          });

          // Sort the criteria list
          criteriaList.sort((a, b) => {
            if (!a.createdAt && !b.createdAt) {
              return 0;
            }
            if (!a.createdAt) {
              return -1;
            }
            if (!b.createdAt) {
              return 1;
            }
            return a.createdAt - b.createdAt;
          });

          setCriteria(criteriaList);
        }
      );

      // Fetch ratings
      const unsubscribeRatings = onSnapshot(
        collection(
          db,
          `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/ratings`
        ),
        (querySnapshot) => {
          const ratingsList = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            ratingsList.push({
              id: doc.id,
              ...data,
            });
          });

          setRatings(ratingsList);
        }
      );

      // Clean up the listeners when the component unmounts
      return () => {
        unsubscribeCriteria();
        unsubscribeRatings();
      };
    }, [userId, examIdProp, taskNum, submissionId]);

    // Adapt the useEffect hook to fetch handwriting image URL and jiix data
    useEffect(() => {
      const jiixStoragePath = `users/${userId}/exams/${examIdProp}/tasks/${submissionId}_${taskNum}.jiix`;

      const jiixStorageRef = ref(storage, jiixStoragePath);

      getBytes(jiixStorageRef)
        .then((objArrayBuffer) => {
          const encoder = new TextDecoder("utf-8");
          const decodedJIIXString = encoder.decode(objArrayBuffer);
          const jiixObj = JSON.parse(decodedJIIXString);
          setJIIX(jiixObj);
        })
        .catch((error) => {
          setJIIX({
            type: "Text",
            "bounding-box": {
              x: 0,
              y: 0,
              width: 546.63,
              height: 6955.63,
            },
            label: "",
            words: [],
            lines: [],
            version: "3",
            id: "MainBlock",
            isPreview: true,
          });
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              console.log("importDataFromStorage: File doesn't exist");
              break;
            default:
              console.log("Error occured in importDataFromStorage (JIIX)");
          }
        });
    }, [userId, examIdProp, submissionId]);

    useEffect(() => {
      // 1. annotations
      onSnapshot(
        query(
          collection(
            db,
            `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/annotations`
          ),
          where("task", "==", taskNum) // Filter for the current task
        ),
        (querySnapshot) => {
          const annotationList = [];
          querySnapshot.forEach((doc) => {
            const m = doc.data();
            annotationList.push({
              id: doc.id,
              ...m,
            });
          });

          setAnnotations(annotationList);
        }
      );
    }, [submissionId]);

    const addAnnotationToDB = async (
      annotationCategory,
      type,
      contentType,
      correct,
      startPos,
      endPos,
      startLineId,
      endLineId,
      repeat,
      comment,
      source
    ) => {
      if (annotationCategory === "content") {
        const docRef = await addDoc(
          collection(
            db,
            `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/annotations`
          ),
          {
            category: annotationCategory,
            type: type,
            correct: correct,
            contentType: contentType,
            startLineId: startLineId,
            endLineId: endLineId,
            source: source,
            task: taskNum, // Include the task property
          }
        );

        return docRef.id;
      } else {
        const docRef = await addDoc(
          collection(
            db,
            `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/annotations`
          ),
          {
            category: annotationCategory,
            type: type,
            correct: correct,
            startPos: startPos,
            endPos: endPos,
            repeat: repeat,
            comment: comment,
            source: source,
            task: taskNum, // Include the task property
          }
        );

        console.log("addAnnotationToDB -> docRef.id", docRef.id);
        return docRef.id;
      }
    };

    const deleteAnnotation = async (annotationId) => {
      const path = `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/annotations/${annotationId}`;
      await deleteDoc(doc(db, path));

      // showDeleteConfirm(annotationId);
    };

    const deleteAllAnnotationsForTask = async () => {
      const annotationsQuery = query(
        collection(
          db,
          `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/annotations`
        ),
        where("task", "==", taskNum)
      );

      const annotationsSnapshot = await getDocs(annotationsQuery);

      const batch = writeBatch(db);

      annotationsSnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      message.success("Alle Annotationen für die Aufgabe entfernt");
    };

    const onChangeCheckbox = (e) => {
      if (examData) {
        const docRef = doc(db, `users/${userId}/exams`, examIdProp);
        setDoc(docRef, { isRatioActive: e.target.checked }, { merge: true });
      }
    };

    const setRatio = (value) => {
      if (examData) {
        const docRef = doc(db, `users/${userId}/exams`, examIdProp);
        setDoc(docRef, { ratio: value }, { merge: true });
      }
    };

    const onChangeCriteriaDescription = (value, id) => {
      const newCriteria = criteria.map((criterion) => {
        if (criterion.id === id) {
          return { ...criterion, description: value };
        } else {
          return criterion;
        }
      });
      setCriteria(newCriteria);
    };

    const onChangeCriteriaRating = async (value, criterionId) => {
      const docRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions/${submissionId}`
      );

      let ratingsMap = {};
      ratingsMap[criterionId] = value;
      let newValues = {};
      newValues["ratings"] = ratingsMap;

      await setDoc(docRef, newValues, { merge: true });
    };

    const onChangeCriteriaPoints = (value, id) => {
      const newCriteria = criteria.map((criterion) => {
        if (criterion.id === id) {
          return { ...criterion, points: value };
        } else {
          return criterion;
        }
      });
      setCriteria(newCriteria);
    };

    const setProcessingOnServer = async (processingBool) => {
      let tasks = submissionData.tasks || [];
      const taskIndexToUpdate = tasks.findIndex(
        (task) => task.taskNum === parseInt(taskNum.replace("task", ""), 10)
      );

      if (taskIndexToUpdate !== -1) {
        tasks[taskIndexToUpdate] = {
          ...tasks[taskIndexToUpdate],
          processing: processingBool,
        };
      }

      const submissionRef = doc(
        db,
        `users/${userId}/exams/${examIdProp}/submissions`,
        submissionId
      );
      await setDoc(submissionRef, { tasks: tasks }, { merge: true });
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentAnnotationId, setCurrentAnnotationId] = useState(null);

    const showDeleteConfirm = (annotationId) => {
      setCurrentAnnotationId(annotationId);
      setIsModalVisible(true);
    };

    const handleDelete = async () => {
      const path = `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/annotations/${currentAnnotationId}`;
      await deleteDoc(doc(db, path));
      setIsModalVisible(false);
      setCurrentAnnotationId(null);
      // Add any additional logic needed after deletion
    };

    const handleConfirm = async () => {
      handleDelete();
      // const docRef = doc(
      //   db,
      //   `users/${userId}/exams/${examIdProp}/submissions/${submissionId}/annotations/${currentAnnotationId}`
      // );
      // await setDoc(
      //   docRef,
      //   {
      //     applyHandwriting: true,
      //     deleted: true,
      //   },
      //   { merge: true }
      // );
      // setIsModalVisible(false);
      // setCurrentAnnotationId(null);
    };

    const [scale, setScale] = useState(1);

    const handleScaleChange = useCallback((newScale) => {
      setScale(newScale);
    }, []);

    // Helper function to get a user-friendly action name
    const getActionName = (action) => {
      switch (action) {
        case "runHandwritingDetection":
          return "Handschrifterkennung und Annotationserstellung";
        case "deleteHandwritingAndAnnotations":
          return "Löschen von Handschrift und Annotationen";
        case "setTooManyDifferences":
          return "Markierung als nicht verarbeitbar";
        case "createAnnotations":
          return "Erstellung von Annotationen";
        case "deleteAnnotations":
          return "Löschen von Annotationen";
        default:
          return action;
      }
    };

    const runAdminAction = async (action, additionalData = {}) => {
      const runAdminActionFunction = httpsCallable(functions, "runAdminAction");
      const submissionNumber = submissionId.replace("submission", "");

      // Start message
      let startMessage = `Starte ${getActionName(
        action
      )} für Aufsatz ${submissionNumber}...`;
      if (action === "runHandwritingDetection") {
        startMessage += " Automatisch zum nächsten Aufsatz gesprungen.";
      }
      message.info({
        content: startMessage,
        duration: 10,
        onClick: () => message.destroy(),
      });

      try {
        await runAdminActionFunction({
          userId,
          examId: examIdProp,
          submissionId,
          taskId: taskNum,
          action,
          ...additionalData,
        });

        // Success message
        message.success({
          content: `${getActionName(
            action
          )} für Aufsatz ${submissionNumber} erfolgreich abgeschlossen.`,
          duration: 10,
          onClick: () => message.destroy(),
        });
      } catch (error) {
        if (error.message !== "deadline-exceeded") {
          message.error({
            content: `Fehler bei ${getActionName(
              action
            )} für Aufsatz ${submissionNumber}: ${error.message}`,
            duration: 10,
            onClick: () => message.destroy(),
          });
        }
      }
    };

    const handleRunHandwritingDetection = (runAnnotations) => {
      runAdminAction("runHandwritingDetection", { runAnnotations });
      handleNextImage();
    };

    const handleDeleteHandwritingAndAnnotations = () => {
      runAdminAction("deleteHandwritingAndAnnotations");
    };

    const handleDeleteAnnotations = () => {
      runAdminAction("deleteAnnotations");
    };

    const handleCreateAnnotations = () => {
      runAdminAction("createAnnotations");
    };

    const handleSetTooManyDifferences = () => {
      runAdminAction("setTooManyDifferences");
    };

    const [activeKeys, setActiveKeys] = useState([]);

    useEffect(() => {
      if (jiix?.isPreview) {
        setActiveKeys(["1"]);
      } else {
        setActiveKeys([]);
      }
    }, [jiix?.isPreview]);

    return (
      <>
        <Modal
          title="Confirm Action"
          open={isModalVisible}
          onOk={handleDelete}
          onCancel={() => setIsModalVisible(false)}
          okText="Annotation löschen"
          okType="danger"
          footer={[
            <Button key="back" onClick={() => setIsModalVisible(false)}>
              Abbrechen
            </Button>,
            <Button key="confirm" onClick={handleConfirm}>
              Handschrift bestätigen
            </Button>,
            <Button key="submit" type="danger" onClick={handleDelete}>
              Annotation löschen
            </Button>,
          ]}
        >
          <p>
            Bitte wählen Sie aus, ob die Annotation gelöscht oder die
            Handschrift bestätigt werden soll. In beiden Fällen wird die
            Annotation entfernt.
          </p>
        </Modal>
        <Content style={leftStyle}>
          {jiix && annotations && examData && (
            <>
              {submissionData?.tooManyDifferences &&
                (user.uid === "uQwUUlHweNhJo4AIRphEgdv6Hqw1" ||
                  user.uid === "6fozyj8RQJO33Q8vWXQLMbYUzct2" ||
                  user.uid === "AoWgLdQMvSfFkqPms5P1fA9UZvZ2") && (
                  <Alert
                    message="zu große Differenzen - bitte Logs überprüfen"
                    type="error"
                  />
                )}
              {(user.uid === "uQwUUlHweNhJo4AIRphEgdv6Hqw1" ||
                user.uid === "6fozyj8RQJO33Q8vWXQLMbYUzct2" ||
                user.uid === "AoWgLdQMvSfFkqPms5P1fA9UZvZ2") &&
                jiix?.isPreview && (
                  <Alert
                    message="Preview-Modus: Handschrifterkennung noch nicht ausgeführt"
                    type="error"
                  />
                )}
              {submissionData?.excludeSubmission && (
                <Alert
                  message="Hinweise zum Ausfüllen der Schreibbögen nicht eingehalten - Aufsatz konnte nicht gelesen werden"
                  type="error"
                />
              )}
              {/* {isMobileSafari && !examData?.isMobileSafariMessageClosed && (
                <Alert
                  message="Die Darstellung kann durch einen Bug des Safari Browsers auf Smartphones und Tablets verschwommen erscheinen - wir empfehlen Chrome für eine optimale Nutzung von zengrade"
                  type="error"
                  closable
                  onClose={closeMobileSafariMessage}
                />
              )} */}
              <div style={{ width: "100%", height: "auto" }}>
                <div
                  style={{
                    transform: `scale(${scale})`,
                    transformOrigin: "top left",
                  }}
                >
                  <HandwritingCanvas
                    userId={userId}
                    examId={examIdProp}
                    submissionId={submissionId}
                    taskNum={taskNum}
                    handwritingImageUrl={imageList[currentIndex]}
                    jiix={jiix}
                    annotations={annotations}
                    visualizeBBs={visualizeBBs}
                    addAnnotationToDB={addAnnotationToDB}
                    deleteAnnotation={deleteAnnotation}
                    interactive={
                      examIdProp !== "CQQ4YNsox6L7JMjmppUK" ||
                      userId === "uQwUUlHweNhJo4AIRphEgdv6Hqw1"
                        ? true
                        : false
                    }
                    setLoadingAnnotations={setLoadingAnnotations}
                    setLoadingJIIX={setLoadingJIIX}
                    setLoadingHandwritingImageUrl={
                      setLoadingHandwritingImageUrl
                    }
                    correctionMarks={
                      examData?.correctionMarks &&
                      examData.correctionMarks.some(
                        (mark) => mark.customAbbreviation
                      )
                        ? examData.correctionMarks
                        : defaultCorrectionMarks
                    }
                    visualizeLines={visualizeLines}
                    visualizeCorrectionText={visualizeCorrectionText}
                    onScaleChange={handleScaleChange}
                    submissionData={submissionData}
                    textType={examData.textType}
                  />
                </div>
              </div>
            </>
          )}
        </Content>
        <Content style={rightStyle}>
          <div style={{ width: "100%", padding: "20px" }}>
            <div>
              {location.pathname.includes("/approve/") &&
                (user.uid === "uQwUUlHweNhJo4AIRphEgdv6Hqw1" ||
                  user.uid === "6fozyj8RQJO33Q8vWXQLMbYUzct2" ||
                  user.uid === "AoWgLdQMvSfFkqPms5P1fA9UZvZ2") &&
                userIdProp !== "AoWgLdQMvSfFkqPms5P1fA9UZvZ2" && (
                  <>
                    <Space direction="horizontal">
                      {!examData?.isReady ? (
                        <Popconfirm
                          title="Klausur wirklich freigeben?"
                          onConfirm={setExamReady}
                          okText="Ja"
                          cancelText="Nein"
                        >
                          <Button danger>
                            Klausur hier freigeben (noch nicht freigegeben)
                          </Button>
                        </Popconfirm>
                      ) : (
                        <Alert
                          message="[ACHTUNG: ANSICHT DES USERS] (Klausur bereits freigegeben)"
                          type="error"
                        />
                      )}
                      <Button
                        icon={<CopyOutlined />}
                        onClick={async () => {
                          try {
                            await navigator.clipboard.writeText(
                              `https://app.zengrade.com/grade/${examIdProp}`
                            );
                            message.success(
                              `URL copied: https://app.zengrade.com/grade/${examIdProp}`
                            );
                          } catch (error) {
                            message.error("Failed to copy URL");
                          }
                        }}
                      />
                    </Space>
                    <br />
                    <br />
                  </>
                )}

              {location.pathname.includes("/approve/") &&
                (user.uid === "uQwUUlHweNhJo4AIRphEgdv6Hqw1" ||
                  user.uid === "6fozyj8RQJO33Q8vWXQLMbYUzct2" ||
                  user.uid === "AoWgLdQMvSfFkqPms5P1fA9UZvZ2") &&
                userIdProp !== "AoWgLdQMvSfFkqPms5P1fA9UZvZ2" &&
                !loadingSubmissionData && (
                  <>
                    <Collapse activeKey={activeKeys} onChange={setActiveKeys}>
                      <Panel
                        header="Admin Aktionen für Aufsatz"
                        key="1"
                        style={{
                          backgroundColor: "#ffcccb",
                          borderColor: "#ff8080",
                        }}
                        headerStyle={{
                          backgroundColor: "#ff8080",
                          color: "white",
                        }}
                      >
                        {" "}
                        <Space direction="vertical">
                          <Popconfirm
                            title="Handschrifterkennung und Annotationen erstellen?"
                            onConfirm={() =>
                              handleRunHandwritingDetection(true)
                            }
                            onCancel={() => {}}
                          >
                            <Button
                              loading={
                                submissionData?.adminActions
                                  ?.runHandwritingDetection === "loading"
                              }
                              disabled={isAnyAdminActionLoading(
                                submissionData?.adminActions
                              )}
                            >
                              Handschrift & Annotationen erstellen + weiter zum
                              nächsten Aufsatz
                            </Button>
                          </Popconfirm>
                          <Popconfirm
                            title="Handschrift und Annotationen löschen?"
                            onConfirm={handleDeleteHandwritingAndAnnotations}
                            onCancel={() => {}}
                          >
                            <Button
                              loading={
                                submissionData?.adminActions
                                  ?.deleteHandwritingAndAnnotations ===
                                "loading"
                              }
                              disabled={isAnyAdminActionLoading(
                                submissionData?.adminActions
                              )}
                            >
                              Handschrift & Annotationen löschen
                            </Button>
                          </Popconfirm>
                          <Popconfirm
                            title="Hinweis setzen, dass nicht verarbeitet werden konnte?"
                            onConfirm={handleSetTooManyDifferences}
                            onCancel={() => {}}
                          >
                            <Button
                              loading={
                                submissionData?.adminActions
                                  ?.setTooManyDifferences === "loading"
                              }
                              disabled={isAnyAdminActionLoading(
                                submissionData?.adminActions
                              )}
                            >
                              Vorgaben nicht eingehalten
                            </Button>
                          </Popconfirm>
                          {/* <Popconfirm
                            title="Nur Handschrifterkennung durchführen?"
                            onConfirm={() =>
                              handleRunHandwritingDetection(false)
                            }
                            onCancel={() => {}}
                          >
                            <Button
                              loading={
                                submissionData?.adminActions
                                  ?.runHandwritingDetection === "loading"
                              }
                              disabled={
                                submissionData?.adminActions
                                  ?.runHandwritingDetection === "loading"
                              }
                            >
                              Nur Handschrifterkennung durchführen
                            </Button>
                          </Popconfirm> */}
                          {/* <Popconfirm
                            title="Annotationen erstellen?"
                            onConfirm={handleCreateAnnotations}
                            onCancel={() => {}}
                          >
                            <Button
                              loading={
                                submissionData?.adminActions
                                  ?.createAnnotations === "loading"
                              }
                              disabled={
                                submissionData?.adminActions
                                  ?.createAnnotations === "loading"
                              }
                            >
                              Nur Annotationen erstellen
                            </Button>
                          </Popconfirm> */}
                          {/* <Popconfirm
                            title="Annotationen löschen?"
                            onConfirm={handleDeleteAnnotations}
                            onCancel={() => {}}
                          >
                            <Button
                              loading={
                                submissionData?.adminActions
                                  ?.deleteAnnotations === "loading"
                              }
                              disabled={
                                submissionData?.adminActions
                                  ?.deleteAnnotations === "loading"
                              }
                            >
                              Annotationen löschen
                            </Button>
                          </Popconfirm> */}
                        </Space>
                      </Panel>
                    </Collapse>
                    <br />
                  </>
                )}
              {!visualizeDebugView &&
                submissionData &&
                !submissionData.excludeSubmission &&
                !loadingSubmissionData &&
                criteria &&
                !isAnyAdminActionLoading(submissionData?.adminActions) && (
                  <>
                    <PointComponent
                      userId={userId}
                      examId={examIdProp}
                      taskNum={taskNum}
                      taskType={taskType}
                      linguisticCriteriaName={linguisticCriteriaName}
                      contentCriteriaName={contentCriteriaName}
                      criteria={criteria}
                      ratings={ratings}
                      submissionData={submissionData}
                      suggestGrades={suggestGrades}
                      loadingSuggestedGrades={loadingSuggestedGrades}
                      lastSubmissionPosRef={lastSubmissionPosRef}
                      getPercentageAchieved={getPercentageAchieved}
                      createMarks={createMarks}
                      addCriterionToDB={addCriterionToDB}
                      MAX_CRITERIA={MAX_CRITERIA}
                      overallCommentLanguage={overallCommentLanguage}
                      onChangeOverallCommentLanguage={
                        onChangeOverallCommentLanguage
                      }
                      changeOverallCommentLanguageInDB={
                        changeOverallCommentLanguageInDB
                      }
                      overallCommentContent={overallCommentContent}
                      onChangeOverallCommentContent={
                        onChangeOverallCommentContent
                      }
                      changeOverallCommentContentInDB={
                        changeOverallCommentContentInDB
                      }
                      examData={examData}
                      onChangeCheckbox={onChangeCheckbox}
                      setRatio={setRatio}
                      onChangeCriteriaDescription={onChangeCriteriaDescription}
                      changeCriterionInDB={changeCriterionInDB}
                      onChangeCriteriaRating={onChangeCriteriaRating}
                      onChangeCriteriaPoints={onChangeCriteriaPoints}
                      deleteCriterion={deleteCriterion}
                      onChangeHinweiseInDB={changeHinweiseInDB}
                      onChangeLobInDB={changeLobInDB}
                      onChangeHinweiseContentInDB={changeHinweiseContentInDB}
                      onChangeLobContentInDB={changeLobContentInDB}
                      onChangeAllgemeineAufgabenstellungInDB={
                        changeAllgemeineAufgabenstellungInDB
                      }
                      onChangeRatingData={onChangeRatingData}
                      annotations={annotations}
                    />
                  </>
                )}
              <br />
              <br />
            </div>
          </div>
        </Content>
      </>
    );
  };

  const leftContentStyle = {
    overflowY: "auto",
    overflowX: "hidden", // Use hidden instead of clip
    width: "60%",
    height: "calc(100% - 100px)",
    position: "fixed",
  };

  const rightContentStyle = {
    overflowY: "auto",
    overflowX: "clip",
    width: "40%",
    height: "calc(100% - 100px)",
    position: "fixed",
    left: "60%",
    backgroundColor: "#e9ebee",
  };

  const renderTaskUI = () => {
    switch (taskType) {
      case "Freitext":
        return (
          <FreitextUI
            leftStyle={leftContentStyle}
            rightStyle={rightContentStyle}
          />
        );
      default:
        return (
          <DefaultUI
            imageSrc={imageList[currentIndex]}
            currentIndex={currentIndex}
            leftStyle={leftContentStyle}
            rightStyle={rightContentStyle}
          />
        );
    }
  };

  // Funktion zum Anzeigen des Modals
  const showCreatePdfModal = () => {
    // Setzen Sie die aktuellen Werte der Refs, wenn das Modal geöffnet wird
    includeGradingKeyRef.current = true;
    includeCorrectionMarksRef.current = true;

    setIsModalVisible(true);
  };

  // Funktion zum Schließen des Modals und Erstellen des PDFs
  const handleCreatePdf = () => {
    message.success(
      "Das PDF wird erzeugt - es dauert einen Moment bis zum Download...",
      8
    );
    setIsModalVisible(false);
    createPDF(
      submissionId,
      showLanguagePartialGrades,
      showLanguagePartialGradesExplanations,
      showLanguageOverallGrade,
      showLanguageImprovementTips,
      showLanguagePraise,
      showContentPartialGrades,
      showContentCorrectionMarkExplanations,
      showContentOverallGrade,
      showContentImprovementTips,
      showContentPraise,
      overallGradeDisplay, // Add this new parameter
      // showOverallGrade,
      underlineLanguageErrorsOnly,
      showHandwritingWithAllMarks
    );
    setIsFeedbackModalVisible(true);
  };

  const createPDF = async (
    submissionId,
    showLanguagePartialGrades,
    showLanguagePartialGradesExplanations,
    showLanguageOverallGrade,
    showLanguageImprovementTips,
    showLanguagePraise,
    showContentPartialGrades,
    showContentCorrectionMarkExplanations,
    showContentOverallGrade,
    showContentImprovementTips,
    showContentPraise,
    // showOverallGrade,
    overallGradeDisplay, // Add this new parameter
    underlineLanguageErrorsOnly,
    showHandwritingWithAllMarks
  ) => {
    try {
      const createPdfFunction = httpsCallable(
        functions,
        "createPdfFunctionSecondGen"
      );

      createPdfFunction({
        examId: examIdProp,
        userId: userId,
        submissionId: submissionId,
        showLanguagePartialGrades,
        showLanguagePartialGradesExplanations,
        showLanguageOverallGrade,
        showLanguageImprovementTips,
        showLanguagePraise,
        showContentPartialGrades,
        showContentCorrectionMarkExplanations,
        showContentOverallGrade,
        showContentImprovementTips,
        showContentPraise,
        // showOverallGrade,
        overallGradeDisplay, // Add this new parameter
        underlineLanguageErrorsOnly,
        showHandwritingWithAllMarks,
      })
        .then((result) => {
          if (result.data && result.data.base64) {
            const base64PDF = result.data.base64;
            // Convert base64 to a Blob
            const binaryString = window.atob(base64PDF); // Decode base64
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: "application/pdf" });
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link and trigger the download
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = `${submissionId}.pdf`; // Set the file name for the download

            // Compatibility handling for iOS devices
            if (
              navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
              navigator.userAgent.match(/AppleWebKit/)
            ) {
              link.target = "_blank";
            }

            document.body.appendChild(link); // Required for Firefox
            link.click();
            document.body.removeChild(link);

            // For iOS devices, manually revoke the object URL to avoid memory leaks
            window.URL.revokeObjectURL(blobUrl);
          } else {
            throw new Error("No data received from createPdfFunction");
          }
        })
        .catch((err) => {
          message.destroy();
          message.error({
            content:
              "Es ist ein Fehler bei der Verarbeitung aufgetreten. Sie erhalten in Kürze eine persönliche E-Mail von uns.",
            duration: 0,
            onClick: () => message.destroy(),
          });
        });
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const [isPointOverviewModalVisible, setIsPointOverviewModalVisible] =
    useState(false);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  const showPointOverviewModal = () => {
    setIsPointOverviewModalVisible(true);
  };

  const showFeedbackLanguage =
    examData?.formData &&
    examData?.formData?.wassoll &&
    examData?.formData?.wassoll?.join()?.includes("Sprache")
      ? 1
      : 0;
  const showFeedbackContent =
    examData?.formData &&
    examData?.formData?.wassoll &&
    examData?.formData?.wassoll?.join()?.includes("Inhalt")
      ? 1
      : 0;

  const [includeRatingsExplanation, setIncludeRatingsExplanation] =
    useState(true);
  const [includeTextfields, setIncludeTextfields] = useState(true);
  const [includePartialGrades, setIncludePartialGrades] = useState(true);
  const [includeOverallGrades, setIncludeOverallGrades] = useState(true);

  return (
    <>
      {isFirefox && (
        <Alert
          closable
          type="error"
          message="In Firefox kann es bei Aufsätzen bei mehr als 8 Seiten zu Anzeigeproblemen kommen. Bitte nutzen Sie Chrome, Safari oder Edge in einer aktuellen Version."
        ></Alert>
      )}
      <Layout style={{ height: "100%" }}>
        <Modal
          open={isFeedbackModalVisible}
          onCancel={() => setIsFeedbackModalVisible(false)}
          footer={null}
        >
          <iframe
            id="JotFormIFrame-241472928725060"
            title="Feedback"
            onload="window.parent.scrollTo(0,0)"
            allowfullscreen="false"
            src={`https://form.jotform.com/241472928725060?userid=${userId}&examid=${examIdProp}&submissionid=${submissionId}&taskid=${taskNum}&spracheAktiv=${showFeedbackLanguage}&inhaltAktiv=${showFeedbackContent}`}
            frameborder="0"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              border: "none",
              overflow: "hidden",
            }}
          ></iframe>
        </Modal>
        <Modal
          // title="Punkteübersicht"
          open={isPointOverviewModalVisible}
          onCancel={() => setIsPointOverviewModalVisible(false)}
          footer={null}
          width={"80%"}
        >
          <GradeOverviewComponent examId={examIdProp} />
        </Modal>
        <Modal
          title="Was soll auf dem Bewertungsbogen angezeigt werden?"
          open={isModalVisible}
          onOk={handleCreatePdf}
          onCancel={() => setIsModalVisible(false)}
          okText="Erzeugen"
          cancelText="Abbrechen"
          bodyStyle={{ maxHeight: "60vh", overflowY: "auto" }}
        >
          <Space direction="vertical">
            {examData &&
              examData.formData?.wassoll &&
              examData.formData.wassoll.join().includes("Sprache") && (
                <>
                  <Checkbox
                    checked={showLanguagePartialGrades}
                    onChange={(e) =>
                      setShowLanguagePartialGrades(e.target.checked)
                    }
                  >
                    Teilnoten Sprache
                  </Checkbox>
                  <Checkbox
                    checked={showLanguagePartialGradesExplanations}
                    onChange={(e) =>
                      setShowLanguagePartialGradesExplanations(e.target.checked)
                    }
                  >
                    Begründungstexte Teilnoten Sprache
                  </Checkbox>
                  <Checkbox
                    checked={showLanguageOverallGrade}
                    onChange={(e) =>
                      setShowLanguageOverallGrade(e.target.checked)
                    }
                  >
                    Gesamtnote Sprache
                  </Checkbox>
                  <Checkbox
                    checked={showLanguageImprovementTips}
                    onChange={(e) =>
                      setShowLanguageImprovementTips(e.target.checked)
                    }
                  >
                    Hinweise zur Verbesserung Sprache
                  </Checkbox>
                  <Checkbox
                    checked={showLanguagePraise}
                    onChange={(e) => setShowLanguagePraise(e.target.checked)}
                  >
                    Lob für Sprache
                  </Checkbox>

                  <br />
                </>
              )}

            {examData &&
              examData.formData?.wassoll &&
              examData.formData.wassoll.join().includes("Inhalt") && (
                <>
                  {" "}
                  <Checkbox
                    checked={showContentPartialGrades}
                    onChange={(e) =>
                      setShowContentPartialGrades(e.target.checked)
                    }
                  >
                    Teilnoten Inhalt
                  </Checkbox>
                  <Checkbox
                    checked={showContentCorrectionMarkExplanations}
                    onChange={(e) =>
                      setShowContentCorrectionMarkExplanations(e.target.checked)
                    }
                  >
                    Begründungstexte zu Korrekturzeichen Inhalt
                  </Checkbox>
                  <Checkbox
                    checked={showContentOverallGrade}
                    onChange={(e) =>
                      setShowContentOverallGrade(e.target.checked)
                    }
                  >
                    Gesamtnote Inhalt
                  </Checkbox>
                  <Checkbox
                    checked={showContentImprovementTips}
                    onChange={(e) =>
                      setShowContentImprovementTips(e.target.checked)
                    }
                  >
                    Hinweise zur Verbesserung Inhalt
                  </Checkbox>
                  <Checkbox
                    checked={showContentPraise}
                    onChange={(e) => setShowContentPraise(e.target.checked)}
                  >
                    Lob für Inhalt
                  </Checkbox>
                  <br />
                </>
              )}

            {examData &&
              examData.formData?.wassoll &&
              examData.formData.wassoll.join().includes("Inhalt") &&
              examData.formData.wassoll.join().includes("Sprache") && (
                <>
                  <hr></hr>
                  <Radio.Group
                    value={overallGradeDisplay}
                    onChange={(e) => setOverallGradeDisplay(e.target.value)}
                  >
                    <Space direction="vertical">
                      <Radio value={null}>Keine Gesamtnote anzeigen</Radio>

                      <Radio value="withCalculation">
                        Gesamtnote Aufsatz mit Rechenweg
                      </Radio>
                      <Radio value="withoutCalculation">
                        Gesamtnote Aufsatz ohne Rechenweg Sprache & Inhalt
                      </Radio>
                    </Space>
                  </Radio.Group>
                  <hr></hr>
                </>
              )}

            <br />

            <Checkbox
              // checked={includeHandwritingLanguage}
              // onChange={(e) => setIncludeHandwritingLanguage(e.target.checked)}
              disabled
              defaultChecked
            >
              Handschrift mit Korrekturzeichen Sprache
            </Checkbox>
            <Checkbox
              checked={underlineLanguageErrorsOnly}
              onChange={(e) => setUnderlineLanguageErrorsOnly(e.target.checked)}
            >
              Sprachliche Fehler in der Handschrift nur unterstreichen
            </Checkbox>
            <Checkbox
              checked={showHandwritingWithAllMarks}
              onChange={(e) => setShowHandwritingWithAllMarks(e.target.checked)}
            >
              Handschrift mit Korrekturzeichen Sprache & Inhalt
            </Checkbox>
          </Space>
        </Modal>
        {examData && !examDataLoading && submissionId && renderTaskUI()}

        <Footer
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#35393C",
          }}
        >
          <Space direction="horizontal">
            {/* Fixed width container for buttons */}
            {/* <Button
            onClick={handlePreviousSubmission}
            shape="round"
            icon={<DoubleLeftOutlined />}
            disabled={
              submissionId === imageDetails[0]?.submissionId ||
              currentIndex === 0
            }
          /> */}
            <Button
              onClick={handlePreviousImage}
              shape="round"
              icon={<LeftOutlined />}
              disabled={currentIndex === 0}
            />
            <Dropdown
              overlay={renderDropdownMenu}
              trigger={["click"]}
              overlayStyle={{ maxHeight: "70vh", overflow: "auto" }}
            >
              <Button>
                {submissionId && taskNum
                  ? `Aufsatz ${submissionId.replace("submission", "")}`
                  : "Aufsatz auswählen"}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              onClick={handleNextImage}
              shape="round"
              icon={<RightOutlined />}
              disabled={currentIndex === imageList.length - 1}
            />
            {/* <Button
            onClick={handleNextSubmission}
            shape="round"
            icon={<DoubleRightOutlined />}
            disabled={
              submissionId ===
                imageDetails[imageDetails.length - 1]?.submissionId ||
              currentIndex === imageList.length - 1
            }
          /> */}

            {submissionData?.gradesSuggested ? (
              <Button
                icon={<PrinterOutlined />}
                shape="round"
                onClick={showCreatePdfModal}
              ></Button>
            ) : (
              <Tooltip title="Bitte erst Noten vorschlagen, um den Bewertungsbogen erzeugen zu können">
                <Button
                  disabled
                  icon={<PrinterOutlined />}
                  shape="round"
                  onClick={showCreatePdfModal}
                ></Button>
              </Tooltip>
            )}

            {/* <Button
            icon={<UsergroupAddOutlined />}
            shape="round"
            onClick={showPointOverviewModal}
          ></Button> */}
          </Space>
        </Footer>
      </Layout>
    </>
  );
};

export default GradeExamPage;
