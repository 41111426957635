import React, { useRef, useEffect, useState, useCallback, memo } from "react";
import { fabric } from "fabric";
import { nanoid } from "nanoid";
import {
  Modal,
  Input,
  message,
  Radio,
  Button,
  Space,
  Checkbox,
  InputNumber,
} from "antd";
import { MinusOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { diffChars } from "diff";
import stringSimilarity from "string-similarity";

import storage from "../utils/storage";

import { isMobile, browserName } from "react-device-detect";

import { getBytes, getDownloadURL, ref, listAll } from "firebase/storage";

import { deleteIcon, multiSelectIcon, editTextIcon } from "./Icons";
import { useAuth } from "../App";
import TextArea from "antd/es/input/TextArea";

const DPI = 96; // for testing
const MM_TO_PIXEL = DPI / 25.4;

const FONT_SIZE_ANNOTATION_TEXT = 45;

const SCALE_FACTOR = 0.4;

const HandwritingCanvas = (props) => {
  // lets set additionalWidthLeft dynymically in case we need to show the content annotations in the left side of the page
  // showFeedbackLanguage && annotations of type content . length > 0 / showFeedbackContent && annotations of type linguistic . length > 0
  // we need to know the most left boundingbox in the jiix by going through all lines
  // then, take the difference between the most left boundingbox and the left page border
  // and subtract this from additionalWidthLeft, so that in total we have width of 150px for the lines
  const additionalHeightTopAndBottom = 50;

  let additionalWidthLeft = 150;

  // Assuming you have a jiix object with lines and bounding boxes

  // Find the most left bounding box
  let mostLeftBoundingBox = Infinity;
  props.jiix.words
    .filter((word) => "bounding-box" in word)
    .forEach((word) => {
      if (word["bounding-box"].x < mostLeftBoundingBox) {
        mostLeftBoundingBox = word["bounding-box"].x;
      }
    });

  // Assuming leftPageBorder is defined
  const leftPageBorder = 0; // replace with actual value

  // Calculate the difference and adjust additionalWidthLeft
  const differenceLeft = mostLeftBoundingBox - leftPageBorder;
  additionalWidthLeft -= differenceLeft;

  // Ensure additionalWidthLeft is not less than 0
  additionalWidthLeft = Math.max(additionalWidthLeft, 0);

  // Find the rightmost bounding box
  let rightmostBoundingBox = -Infinity;
  props.jiix.words
    .filter((word) => "bounding-box" in word)
    .forEach((word) => {
      const rightmostPoint =
        word["bounding-box"].x + word["bounding-box"].width;
      if (rightmostPoint > rightmostBoundingBox) {
        rightmostBoundingBox = rightmostPoint;
      }
    });

  let spaceBetweenLastWordAndLanguageCorrectionMarks = 100;
  let startingPosLanguageCorrectionMarks =
    additionalWidthLeft +
    spaceBetweenLastWordAndLanguageCorrectionMarks +
    rightmostBoundingBox * MM_TO_PIXEL;

  let additionalWidthRight = 300;

  // Calculate the difference between the rightmost bounding box and the starting position of language correction marks
  const differenceRight =
    startingPosLanguageCorrectionMarks - rightmostBoundingBox * MM_TO_PIXEL;

  // Subtract the difference from additionalWidthRight
  additionalWidthRight -= differenceRight;

  // Ensure additionalWidthRight is not less than 0
  additionalWidthRight = Math.max(additionalWidthRight, 0);

  let { user } = useAuth();

  const htmlCanvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const containerRef = useRef(null);
  const startPosRef = useRef(null);
  const endPosRef = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [correctedWord, setCorrectedWord] = useState("");
  const [originalWord, setOriginalWord] = useState("");
  const [comment, setComment] = useState("");
  const [annotationToUpdateId, setAnnotationToUpdateId] = useState("");
  const [annotationCategory, setAnnotationCategory] = useState("linguistic");
  const [annotationType, setAnnotationType] = useState("R");
  const [contentType, setContentType] = useState("");

  const [startLineId, setStartLineId] = useState(null);
  const [endLineId, setEndLineId] = useState(null);

  const isMultiSelectEnabledRef = useRef(false);
  const selectedObjectsRef = useRef(null);
  selectedObjectsRef.current = [];

  const annotationsRef = useRef(null);
  annotationsRef.current = props.annotations;

  const getMistakeType = (originalText, correctedText) => {
    // compare the two texts letter by letter and if the difference is just punctuation, then it's a punctuation mistake
    let diffs = diffChars(originalText, correctedText);
    let punctuationMistakes = [".", ",", ";", ":", "!", "?"];
    for (let i = 0; i < diffs.length; i++) {
      if (diffs[i].added || diffs[i].removed) {
        if (punctuationMistakes.includes(diffs[i].value.trim())) {
          return "Z";
        }
      }
    }

    let similarity = stringSimilarity.compareTwoStrings(
      originalText,
      correctedText
    );

    if (similarity > 0.6) {
      return "R";
    }

    // if the difference is more than a letter, then it's a grammar or an expression mistake
    if (similarity > 0.3) {
      return "G";
    }

    return "A";
  };

  // bla

  const toggleMultiSelectMode = () => {
    // if multi-select button hit the first time, then active multi-select mode
    if (!isMultiSelectEnabledRef.current) {
      message.info("Multi-Select aktiviert");
      isMultiSelectEnabledRef.current = !isMultiSelectEnabledRef.current;
    } else {
      // if multi-select button hit the second time, create group if there are more than one selected objects
      if (selectedObjectsRef.current.length > 1) {
        // order selected objects by wordOrder ascending
        selectedObjectsRef.current.sort((a, b) => {
          if (a.wordOrder < b.wordOrder) {
            return -1;
          } else if (a.wordOrder > b.wordOrder) {
            return 1;
          } else {
            return 0;
          }
        });

        addAnnotation(null, null);
      }
    }
  };

  // Call prefillModalInputs when the Modal is about to open
  const showModal = () => {
    setIsModalVisible(true);
  };

  const deselectActiveObject = () => {
    if (fabricCanvasRef.current) {
      fabricCanvasRef.current.discardActiveObject(); // Unselect the active object
      fabricCanvasRef.current.renderAll(); // Re-render the canvas to apply changes
    }
  };

  const resetCanvasObjects = () => {
    setIsModalVisible(false);
    setCorrectedWord("");
    setComment("");
    setAnnotationCategory("linguistic");
    setAnnotationType("R");
    deselectActiveObject();
    setStartLineId(null);
    setEndLineId(null);

    fabricCanvasRef.current.getObjects().forEach(function (object) {
      if (object.type === "group" && "annotationId") {
        object.forEachObject(function (object) {
          object.set({ stroke: object.customColor });
        });
      } else if (object.type === "rect" && "wordId" in object) {
        object.set("fill", "none");
        object.set("opacity", props.visualizeBBs ? 0.05 : 0);
      } else if (object.type === "text" && "annotationId" in object) {
        object.set({ fill: object.customColor, underline: false });
      }
    });
    isMultiSelectEnabledRef.current = false;
    fabricCanvasRef.current.selection = false;

    selectedObjectsRef.current = [];
    fabricCanvasRef.current.renderAll();
  };

  const handleOk = async () => {
    if (annotationToUpdateId) {
      // Delete the annotation and then re-add
      await props.deleteAnnotation(annotationToUpdateId);
      setAnnotationToUpdateId("");
    }

    await props.addAnnotationToDB(
      annotationCategory,
      annotationType,
      contentType,
      correctedWord,
      startPosRef.current,
      endPosRef.current,
      startLineId,
      endLineId,
      false,
      comment ? comment : "",
      "user"
    );

    resetCanvasObjects();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCorrectedWord("");
    setComment("");
    setAnnotationCategory("linguistic");
    setAnnotationToUpdateId("");
    setAnnotationType("R");
    setContentType("");
    setStartLineId(null);
    setEndLineId(null);
    deselectActiveObject();

    resetCanvasObjects();
  };

  const getCorrectionMarkColor = (correctionMarks, annotationType) => {
    const correctionMark = correctionMarks.find(
      (mark) =>
        mark.abbreviation === annotationType || mark.type === annotationType
    );

    return correctionMark
      ? correctionMark.customColor
        ? correctionMark.customColor
        : correctionMark.color
      : "#08F"; // default color if not found
  };

  async function addAnnotation(eventData, transform) {
    if (transform === null) {
      startPosRef.current = selectedObjectsRef.current[0].startPos;
      const elemCount = selectedObjectsRef.current.length;
      endPosRef.current = selectedObjectsRef.current[elemCount - 1].endPos;

      setStartLineId(selectedObjectsRef.current[0].lineId);
      setEndLineId(selectedObjectsRef.current[elemCount - 1].lineId);

      const originalWord = selectedObjectsRef.current
        .map((obj) => obj.suggestion)
        .join(" ");
      const correctedWord = selectedObjectsRef.current
        .map((obj) => obj.suggestion)
        .join(" ");

      setOriginalWord(originalWord);
      setCorrectedWord(correctedWord);

      const annotationType = getMistakeType(originalWord, correctedWord);
      setAnnotationType(annotationType);
    } else {
      if (
        "annotationId" in transform.target &&
        "annotationCategory" in transform.target &&
        transform.target.annotationCategory === "linguistic"
      ) {
        let annotationId = transform.target.annotationId;
        let a = annotationsRef.current.filter(
          (obj) => obj.id === annotationId
        )[0];
        startPosRef.current = a.startPos;
        endPosRef.current = a.endPos;

        setOriginalWord(a.correct);
        setCorrectedWord(a.correct);
        setComment(a.comment);
        setAnnotationToUpdateId(a.id);
        setAnnotationCategory(transform.target.annotationCategory);
        setAnnotationType(a.type);
      } else if (
        "annotationId" in transform.target &&
        "annotationCategory" in transform.target &&
        transform.target.annotationCategory === "content"
      ) {
        let annotationId = transform.target.annotationId;
        let a = annotationsRef.current.filter(
          (obj) => obj.id === annotationId
        )[0];

        setStartLineId(a.startLineId);
        setEndLineId(a.endLineId);

        setCorrectedWord(a.correct);
        setAnnotationToUpdateId(a.id);
        setAnnotationCategory(transform.target.annotationCategory);
        setAnnotationType(a.type);
        setContentType(a.contentType);
      } else {
        startPosRef.current = transform.target.startPos;
        endPosRef.current = transform.target.endPos;

        setStartLineId(transform.target.lineId);
        setEndLineId(transform.target.lineId);

        setOriginalWord(transform.target.suggestion);
        setCorrectedWord(transform.target.suggestion);
      }
    }

    showModal();
  }

  useEffect(() => {
    if (fabricCanvasRef.current) {
      return;
    }

    try {
      processJIIX();
    } catch (error) {
      throw error;
    } finally {
      props.setLoadingJIIX(false);
    }
    // disposing the canvas stops memory leaks on mobile browser
    return () => {
      if (fabricCanvasRef.current) {
        fabricCanvasRef.current.dispose();
        fabricCanvasRef.current = null;
      }
    };
  }, [props.jiix]);

  const addAnnotations = useCallback(async () => {
    let comments = [];

    const annotationsToRemove = fabricCanvasRef.current
      .getObjects()
      .filter((object) => "annotationId" in object);

    annotationsToRemove.forEach((object) =>
      fabricCanvasRef.current.remove(object)
    );

    fabricCanvasRef.current.discardActiveObject(); // Unselect the active object
    fabricCanvasRef.current.renderAll(); // Re-render the canvas to apply changes

    const mistakeTypesPerLineMap = new Map();
    const lineIdToAnnotationIdsMap = new Map();

    // countPerLineMaps does not reflect the proper order of annotations in the respective line. The textObject annotations created from countPerLineMaps need to be sorted by startWordBB.x
    let word_length_sum = 0;

    for (let i = 0; i < props.jiix.words.length; i++) {
      props.jiix.words[i].sentenceStartPos = word_length_sum;
      let current_word = props.jiix.words[i];
      word_length_sum += current_word.label.length;
    }

    annotationsRef.current
      .filter((annotation) => annotation.category !== "content")
      .sort((a, b) => {
        let aStartWordNum, bStartWordNum;

        // Calculate start_word_num for annotation a
        let word_length_sum = 0;
        for (let i = 0; i < props.jiix.words.length; i++) {
          let current_word = props.jiix.words[i];
          word_length_sum += current_word.label.length;
          if (
            (word_length_sum >= a.startPos &&
              current_word.label !== " " &&
              current_word.label !== "\n") ||
            i === props.jiix.words.length - 1
          ) {
            aStartWordNum = i;
            break;
          }
        }

        // Calculate start_word_num for annotation b
        word_length_sum = 0;
        for (let i = 0; i < props.jiix.words.length; i++) {
          let current_word = props.jiix.words[i];
          word_length_sum += current_word.label.length;
          if (
            (word_length_sum >= b.startPos &&
              current_word.label !== " " &&
              current_word.label !== "\n") ||
            i === props.jiix.words.length - 1
          ) {
            bStartWordNum = i;
            break;
          }
        }

        // Compare startWordBB.x values
        const aStartWord = props.jiix.words[aStartWordNum];
        const bStartWord = props.jiix.words[bStartWordNum];

        return aStartWord["bounding-box"].x - bStartWord["bounding-box"].x;
      })
      .forEach((annotation) => {
        let canvasGroupObjectsNonText = [];
        let canvasGroupObjectsText = [];

        const snippet = props.jiix.label.substring(
          annotation.startPos,
          annotation.endPos
        );

        // case: word is removed, handle spaces to length of strikethrough is correct
        if (annotation.correct.length === 0) {
          if (snippet.startsWith(" ") || snippet.startsWith("\n")) {
            annotation.startPos = annotation.startPos + 1;
          }

          if (snippet.endsWith(" ") || snippet.endsWith("\n")) {
            annotation.endPos = annotation.endPos - 1;
          }
        }

        let word_length_sum = 0;
        let start_word_num;
        for (let i = 0; i < props.jiix.words.length; i++) {
          let current_word = props.jiix.words[i];
          word_length_sum += current_word.label.length;
          if (
            (word_length_sum >= annotation.startPos &&
              current_word.label !== " " &&
              current_word.label !== "\n") ||
            i === props.jiix.words.length - 1
          ) {
            start_word_num = i;
            break;
          }
        }

        word_length_sum = 0;
        let end_word_num;
        for (let i = 0; i < props.jiix.words.length; i++) {
          let current_word = props.jiix.words[i];
          word_length_sum += current_word.label.length;
          if (
            (word_length_sum >= annotation.endPos &&
              current_word.label !== " " &&
              current_word.label !== "\n") ||
            i === props.jiix.words.length - 1
          ) {
            end_word_num = i;
            break;
          }
        }

        if (annotation.startPos === annotation.endPos) {
          annotation.endPos = annotation.endPos + 1;
          annotation.correct =
            annotation.correct + props.jiix.words[end_word_num].label;
        }

        const startWord = props.jiix.words[start_word_num];
        const endWord = props.jiix.words[end_word_num];

        let wordNums = [];
        for (let i = start_word_num; i <= end_word_num; i++) {
          wordNums.push(i);
        }

        const startLine = startWord.lineId;
        const endLine = endWord.lineId;

        let lineIds = [];
        for (let i = startLine; i <= endLine; i++) {
          lineIds.push(i);
        }

        lineIds = lineIds.filter((lineId) => {
          return props.jiix.words.some((word) => word.lineId === lineId);
        });

        const annotationParts = splitArrayIntoParts(
          annotation.correct.split(" "),
          lineIds.length
        );

        lineIds.forEach((lineId, lineIndex) => {
          const wordsInAllLines = [];
          wordNums.forEach((wordNum) => {
            wordsInAllLines.push(props.jiix.words[wordNum]);
          });

          const wordsInCurrentLine = wordsInAllLines.filter(
            (word) => word.lineId === lineId && "bounding-box" in word
          );

          const currentLineStartWord = wordsInCurrentLine[0];

          const currentLineEndWord =
            wordsInCurrentLine[wordsInCurrentLine.length - 1];

          const startWordBB = currentLineStartWord["bounding-box"];
          const lowerLeftPointX = Math.round(startWordBB.x);
          const lowerLeftPointY = Math.round(
            startWordBB.y + startWordBB.height
          );
          const endWordBB = currentLineEndWord["bounding-box"];
          const lowerRightPointX = Math.round(endWordBB.x + endWordBB.width);
          const lowerRightPointY = Math.round(endWordBB.y + endWordBB.height);
          const upperRightPointX = Math.round(endWordBB.x + endWordBB.width);
          const upperRightPointY = Math.round(endWordBB.y);

          const maxPointY = Math.max(lowerLeftPointY, lowerRightPointY);

          const upperLeftPointX = Math.round(startWordBB.x);
          const upperLeftPointY = Math.round(startWordBB.y);

          let startWordBBRect = new fabric.Rect({
            left:
              additionalWidthLeft * SCALE_FACTOR +
              startWordBB.x * MM_TO_PIXEL * SCALE_FACTOR,
            top: startWordBB.y * MM_TO_PIXEL * SCALE_FACTOR,
            width: startWordBB.width * MM_TO_PIXEL * SCALE_FACTOR,
            height: startWordBB.height * MM_TO_PIXEL * SCALE_FACTOR,
            fill: "transparent",
            stroke: "transparent",
            strokeWidth: 1,
            selectable: false,
            hasRotatingPoint: false,
            hasBorders: false,
            hasControls: false,
          });

          let lineColor = getCorrectionMarkColor(
            props.correctionMarks,
            // annotation.type
            annotation.type.split("|")[0]
          );

          const endsWithPunctuationForCorrect = /[.,:;!?]$/.test(
            annotation.correct[annotation.correct.length - 1]
          );
          const endsWithPunctuationForSnippet = /[.,:;!?]$/.test(
            snippet[snippet.length - 1]
          );
          const punctuation = annotation.correct[annotation.correct.length - 1];
          const differsOnlyByLastCharacter =
            (endsWithPunctuationForCorrect &&
              endsWithPunctuationForSnippet &&
              annotation.correct.slice(0, -1) === snippet.slice(0, -1) &&
              annotation.correct.slice(-1) !== snippet.slice(-1)) ||
            (endsWithPunctuationForCorrect &&
              !endsWithPunctuationForSnippet &&
              annotation.correct.slice(0, -1) === snippet &&
              annotation.correct.slice(-1) !== snippet.slice(-1));

          if (lineIds.length > 1 && lineId !== lineIds[0]) {
            if (props.visualizeLines) {
              const x1 =
                additionalWidthLeft * SCALE_FACTOR +
                lowerLeftPointX * MM_TO_PIXEL * SCALE_FACTOR -
                30 * SCALE_FACTOR;
              const y1 = maxPointY * MM_TO_PIXEL * SCALE_FACTOR;
              const x2 =
                additionalWidthLeft * SCALE_FACTOR +
                lowerLeftPointX * MM_TO_PIXEL * SCALE_FACTOR;
              const y2 = maxPointY * MM_TO_PIXEL * SCALE_FACTOR;
              let dashedLine = new fabric.Line([x1, y1, x2, y2], {
                stroke: lineColor, // Use the lineColor variable here
                strokeWidth: 5 * SCALE_FACTOR,
                strokeDashArray: [5 * SCALE_FACTOR, 5 * SCALE_FACTOR],
                hasBorders: false,
                hasControls: false,

                selectable: false,
                hasRotatingPoint: false,
                customColor: lineColor,
              });

              canvasGroupObjectsNonText.push(dashedLine);
            }
          }

          let part1 = "";
          let part3 = "";
          if (
            annotation.endPos - annotation.startPos <
            startWord.label.length
          ) {
            console.log(
              "CASE: annotationen kleiner als BB: ",
              annotation.id,
              annotation.correct,
              startWord.label,
              annotation.endPos,
              annotation.startPos,
              startWord.label.length
            );

            part1 = startWord.label.substring(
              0,
              annotation.startPos - startWord.sentenceStartPos
            );

            part3 = startWord.label.substring(
              annotation.endPos - startWord.sentenceStartPos
            );

            console.log(
              `part1 -> "${part1}" | annotation.correct -> "${annotation.correct}" | part3 -> "${part3}"`
            );
          }

          if (annotation.correct.length > 0) {
            const x1 =
              additionalWidthLeft * SCALE_FACTOR +
              lowerLeftPointX * MM_TO_PIXEL * SCALE_FACTOR;
            const y1 = maxPointY * MM_TO_PIXEL * SCALE_FACTOR;
            const x2 =
              additionalWidthLeft * SCALE_FACTOR +
              lowerRightPointX * MM_TO_PIXEL * SCALE_FACTOR;
            const y2 = maxPointY * MM_TO_PIXEL * SCALE_FACTOR;

            // Check if annotation.correct ends with punctuation and if snippet differs only by the last character
            if (
              endsWithPunctuationForCorrect &&
              differsOnlyByLastCharacter &&
              !annotation.type.includes("|") &&
              annotation.type.includes("Z")
            ) {
              lineColor = getCorrectionMarkColor(
                props.correctionMarks,
                // annotation.type
                annotation.type.split("|")[0]
              );

              let textObject = new fabric.Text(punctuation, {
                left: x2 - 10 * SCALE_FACTOR,
                top: y2 - 90 * SCALE_FACTOR,
                fill: lineColor,
                stroke: lineColor,
                fontSize: 80 * SCALE_FACTOR,
                selectable: false,
                hasRotatingPoint: false,
                hasControls: false,
                hasBorders: false,
                customColor: lineColor,
              });

              // canvasGroupObjectsNonText.push(textObject);
              canvasGroupObjectsText.push(textObject);
            } else {
              if (props.visualizeLines) {
                if (annotation.type.includes("|")) {
                  let solidLine1 = new fabric.Line([x1, y1, x2, y2], {
                    stroke: lineColor, // Use the lineColor variable here
                    strokeWidth: 2.5 * SCALE_FACTOR,
                    selectable: false,
                    hasRotatingPoint: false,
                    customColor: lineColor,
                    hasControls: false,
                    hasBorders: false,
                  });
                  canvasGroupObjectsNonText.push(solidLine1);
                  let solidLine2 = new fabric.Line(
                    [x1, y1 + 5 * SCALE_FACTOR, x2, y2 + 5 * SCALE_FACTOR],
                    {
                      stroke: getCorrectionMarkColor(
                        props.correctionMarks,
                        annotation.type.split("|")[1]
                      ), // Use the lineColor variable here
                      strokeWidth: 2.5 * SCALE_FACTOR,
                      selectable: false,
                      hasRotatingPoint: false,
                      customColor: getCorrectionMarkColor(
                        props.correctionMarks,
                        annotation.type.split("|")[1]
                      ),
                      hasControls: false,
                      hasBorders: false,
                    }
                  );
                  canvasGroupObjectsNonText.push(solidLine2);
                } else {
                  let solidLine = new fabric.Line([x1, y1, x2, y2], {
                    stroke: lineColor, // Use the lineColor variable here
                    strokeWidth: 5 * SCALE_FACTOR,
                    selectable: false,
                    hasRotatingPoint: false,
                    customColor: lineColor,
                    hasControls: false,
                    hasBorders: false,
                  });
                  canvasGroupObjectsNonText.push(solidLine);
                }

                // if (annotation.type.includes("|")) {
                //   // Add vertical line instead of "⊥" text
                //   let verticalLine = new fabric.Line(
                //     [x1 - 2.5, y1 - 30, x1 - 2.5, y1],
                //     {
                //       stroke: lineColor,
                //       strokeWidth: 5,
                //       selectable: false,
                //       hasControls: false,
                //       hasBorders: false,
                //     }
                //   );
                //   canvasGroupObjectsNonText.push(verticalLine);
                // }
              } else {
                canvasGroupObjectsNonText.push(startWordBBRect);
              }
            }
          } else {
            const x1 =
              additionalWidthLeft * SCALE_FACTOR +
              lowerLeftPointX * MM_TO_PIXEL * SCALE_FACTOR;
            const y1 = maxPointY * MM_TO_PIXEL * SCALE_FACTOR;
            const x2 =
              additionalWidthLeft * SCALE_FACTOR +
              upperRightPointX * MM_TO_PIXEL * SCALE_FACTOR;
            const y2 = upperRightPointY * MM_TO_PIXEL * SCALE_FACTOR;
            if (annotation.type.includes("|")) {
              let solidLine1 = new fabric.Line([x1, y1, x2, y2], {
                stroke: lineColor, // Use the lineColor variable here
                strokeWidth: 2.5 * SCALE_FACTOR,
                customColor: lineColor,
                hasControls: false,
                hasBorders: false,
                selectable: false,
                hasRotatingPoint: false,
              });
              canvasGroupObjectsNonText.push(solidLine1);

              let solidLine2 = new fabric.Line(
                [x1, y1 + 5 * SCALE_FACTOR, x2, y2 + 5 * SCALE_FACTOR],
                {
                  stroke: getCorrectionMarkColor(
                    props.correctionMarks,
                    annotation.type.split("|")[1]
                  ), // Use the lineColor variable here
                  strokeWidth: 2.5 * SCALE_FACTOR,
                  customColor: getCorrectionMarkColor(
                    props.correctionMarks,
                    annotation.type.split("|")[1]
                  ),
                  hasControls: false,
                  hasBorders: false,
                  selectable: false,
                  hasRotatingPoint: false,
                }
              );
              canvasGroupObjectsNonText.push(solidLine2);
            } else {
              let solidLine = new fabric.Line([x1, y1, x2, y2], {
                stroke: lineColor, // Use the lineColor variable here
                strokeWidth: 5 * SCALE_FACTOR,
                customColor: lineColor,
                hasControls: false,
                hasBorders: false,
                selectable: false,
                hasRotatingPoint: false,
              });
              canvasGroupObjectsNonText.push(solidLine);
            }

            // if (annotation.type.includes("|")) {
            //   // Add vertical line instead of "⊥" text
            //   let verticalLine = new fabric.Line(
            //     [x1 - 2.5, y1 - 30, x1 - 2.5, y1],
            //     {
            //       stroke: lineColor,
            //       strokeWidth: 5,
            //       selectable: false,
            //       hasControls: false,
            //       hasBorders: false,
            //     }
            //   );
            //   canvasGroupObjectsNonText.push(verticalLine);
            // }
          }

          if (lineIds.length > 1 && lineId !== lineIds[lineIds.length - 1]) {
            if (props.visualizeLines) {
              const x1 =
                additionalWidthLeft * SCALE_FACTOR +
                lowerRightPointX * MM_TO_PIXEL * SCALE_FACTOR;
              const y1 = maxPointY * MM_TO_PIXEL * SCALE_FACTOR;
              const x2 =
                additionalWidthLeft * SCALE_FACTOR +
                lowerRightPointX * MM_TO_PIXEL * SCALE_FACTOR +
                30 * SCALE_FACTOR;
              const y2 = maxPointY * MM_TO_PIXEL * SCALE_FACTOR;

              let dashedLine = new fabric.Line([x1, y1, x2, y2], {
                stroke: lineColor, // Use the lineColor variable here
                strokeWidth: 5 * SCALE_FACTOR,
                strokeDashArray: [5 * SCALE_FACTOR, 5 * SCALE_FACTOR],
                selectable: false,
                hasRotatingPoint: false,
                customColor: lineColor,
                hasControls: false,
                hasBorders: false,
              });

              canvasGroupObjectsNonText.push(dashedLine);
            }
          }

          if (lineId === lineIds[0]) {
            if (annotation.correct.length > 0) {
              if (annotation.comment) {
                comments.push({
                  annotationId: annotation.id,
                  comment: annotation.comment,
                });

                const x = upperRightPointX * MM_TO_PIXEL * SCALE_FACTOR;
                const y = upperRightPointY * MM_TO_PIXEL * SCALE_FACTOR;
                let textObject = new fabric.Text(`K${comments.length}`, {
                  left: additionalWidthLeft * SCALE_FACTOR + x,
                  top:
                    y +
                    FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR -
                    25 * SCALE_FACTOR,
                  fill: lineColor,
                  stroke: lineColor,
                  fontSize: 20 * SCALE_FACTOR,
                  selectable: false,
                  hasRotatingPoint: false,
                  customColor: lineColor,
                  hasControls: false,
                  hasBorders: false,
                });

                canvasGroupObjectsText.push(textObject);

                // Draw a circle around the Text
                let circle = new fabric.Circle({
                  left:
                    additionalWidthLeft * SCALE_FACTOR + x - 5 * SCALE_FACTOR, // Adjust position to encircle the Text
                  top: y + 15 * SCALE_FACTOR, // Adjust position to encircle the Text
                  radius: 15 * SCALE_FACTOR,
                  fill: "transparent",
                  stroke: lineColor,
                  strokeWidth: 2 * SCALE_FACTOR,
                  selectable: false,
                  customColor: lineColor,
                  hasRotatingPoint: false,
                  hasControls: false,
                  hasBorders: false,
                });
                canvasGroupObjectsNonText.push(circle);
              }

              if (
                endsWithPunctuationForCorrect &&
                differsOnlyByLastCharacter &&
                !annotation.type.includes("|") &&
                annotation.type.includes("Z")
              ) {
                canvasGroupObjectsNonText.push(startWordBBRect);
              } else {
                let annotionTxt;
                if (lineIds.length > 1) {
                  annotionTxt = annotationParts[0].join(" ") + " →";
                } else {
                  annotionTxt = annotation.correct;
                }
                const x = upperLeftPointX * MM_TO_PIXEL * SCALE_FACTOR;
                const y = upperLeftPointY * MM_TO_PIXEL * SCALE_FACTOR;

                let textObject = new fabric.Text(
                  props.visualizeCorrectionText
                    ? annotation.endPos - annotation.startPos <
                      startWord.label.length
                      ? (part1 + " " + annotionTxt + " " + part3).trim()
                      : annotionTxt
                    : "",
                  {
                    left: additionalWidthLeft * SCALE_FACTOR + x,
                    top: y - FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR,
                    fill: lineColor,
                    stroke: lineColor,
                    fontSize: FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR,
                    hasControls: false,
                    hasBorders: false,
                    selectable: false,
                    hasRotatingPoint: false,
                    customColor: lineColor,
                  }
                );

                canvasGroupObjectsText.push(textObject);
              }
            }
          } else if (lineId !== lineIds[lineIds.length - 1]) {
            let annotionPartsTxt =
              annotationParts[lineIndex]?.length > 1
                ? annotationParts[lineIndex].join(" ")
                : annotationParts[lineIndex];
            let annotionTxt =
              lineIds.length > 1 && annotionPartsTxt
                ? "← " + annotionPartsTxt + " →"
                : "";

            const x = upperLeftPointX * MM_TO_PIXEL * SCALE_FACTOR;
            const y = upperLeftPointY * MM_TO_PIXEL * SCALE_FACTOR;

            let textObject = new fabric.Text(
              props.visualizeCorrectionText
                ? annotation.endPos - annotation.startPos <
                  startWord.label.length
                  ? (part1 + " " + annotionTxt + " " + part3).trim()
                  : annotionTxt
                : "",
              {
                left:
                  additionalWidthLeft * SCALE_FACTOR +
                  x -
                  FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR,
                top: y - FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR,
                fill: lineColor,
                stroke: lineColor,
                fontSize: FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR,
                hasControls: false,
                hasBorders: false,
                selectable: false,
                hasRotatingPoint: false,
                customColor: lineColor,
              }
            );

            // canvasGroupObjectsNonText.push(textObject);
            canvasGroupObjectsText.push(textObject);
          } else {
            let annotionPartsTxt =
              annotationParts[lineIndex]?.length > 1
                ? annotationParts[lineIndex].join(" ")
                : annotationParts[lineIndex];
            let annotionTxt =
              lineIds.length > 1 && annotionPartsTxt
                ? "← " + annotionPartsTxt
                : "";

            const x = upperLeftPointX * MM_TO_PIXEL * SCALE_FACTOR;
            const y = upperLeftPointY * MM_TO_PIXEL * SCALE_FACTOR;

            let textObject = new fabric.Text(
              props.visualizeCorrectionText
                ? annotation.endPos - annotation.startPos <
                  startWord.label.length
                  ? (part1 + " " + annotionTxt + " " + part3).trim()
                  : annotionTxt
                : "",
              {
                left:
                  additionalWidthLeft * SCALE_FACTOR +
                  x -
                  FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR,
                top: y - FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR,
                fill: lineColor,
                stroke: lineColor,
                fontSize: FONT_SIZE_ANNOTATION_TEXT * SCALE_FACTOR,
                hasControls: false,
                hasBorders: false,
                selectable: false,
                hasRotatingPoint: false,
                customColor: lineColor,
              }
            );

            canvasGroupObjectsText.push(textObject);
          }

          // dont run for pencil or typed
          // if (props.textType === "scan" || !props.textType) {
          const overlappingAnnotations = fabricCanvasRef.current
            .getObjects()
            .filter((existingObj) => {
              if (existingObj.type !== "group") return false;
              // Überprüfen Sie die Überschneidung für jedes Objekt separat
              return canvasGroupObjectsNonText.some((newObj) => {
                return intersectRect(
                  newObj.getBoundingRect(),
                  existingObj.getBoundingRect()
                );
              });
            });
          if (overlappingAnnotations.length > 0) {
            canvasGroupObjectsText.forEach((obj) => {
              obj.set("top", obj.top - 40 * SCALE_FACTOR); // TODO: for tests aktuell 40, sollte 60 sein!
            });
          }
          // }

          let group = new fabric.Group(
            canvasGroupObjectsNonText.concat(canvasGroupObjectsText),
            {
              annotationId: annotation.id,
              annotationCategory: "linguistic",
              evented: props.interactive,
              selectable: props.interactive,
              // dontShowDeleteControl: props.dontShowDeleteControl,
              dontShowDeleteControl:
                ["H"].includes(annotation.type) || props.dontShowDeleteControl,
            }
          );

          group.top += additionalHeightTopAndBottom * SCALE_FACTOR;

          fabricCanvasRef.current.add(group);
          fabricCanvasRef.current.bringToFront(group);

          canvasGroupObjectsNonText = [];
          canvasGroupObjectsText = [];

          const existingLetters = mistakeTypesPerLineMap.get(lineId) || "";

          // let currentType = annotation.type.includes("|")
          //   ? annotation.type
          //       .split("|")
          //       .map((type) => {
          //         const mark = props.correctionMarks.find(
          //           (mark) => mark.abbreviation === type
          //         );
          //         return mark ? mark.customAbbreviation : type;
          //       })
          //       .join("|")
          //   : (() => {
          //       const mark = props.correctionMarks.find(
          //         (mark) => mark.abbreviation === annotation.type
          //       );
          //       return mark ? mark.customAbbreviation : annotation.type;
          //     })();

          let currentTypes = annotation.type.split("|").map((type) => {
            const mark = props.correctionMarks.find(
              (mark) => mark.abbreviation === type
            );
            return mark ? mark.customAbbreviation || mark.abbreviation : type;
          });

          currentTypes.forEach((currentType, currentTypeIndex) => {
            // Trim the currentType to remove any leading/trailing spaces
            const trimmedType = currentType.trim();

            // Find the matching correction mark, with a null check
            const matchingMark = props.correctionMarks.find(
              (mark) => mark.customAbbreviation === trimmedType
            );

            // Add the color code and additionally the line index and total number of lines
            currentType += `[${
              matchingMark
                ? getCorrectionMarkColor(
                    props.correctionMarks,
                    matchingMark.abbreviation
                  )
                : "#08F" // Default color if no match is found
            }-${lineIndex + 1}/${lineIds.length}]`;

            let updatedValue = mistakeTypesPerLineMap.get(lineId) || "";
            if (lineIds.length > 1 && lineId !== lineIds[0]) {
              if (currentTypeIndex > 0) {
                updatedValue = "|" + currentType + "," + updatedValue;
              } else {
                updatedValue = currentType + "," + updatedValue;
              }
            } else {
              if (currentTypeIndex > 0) {
                updatedValue += "|" + currentType + ",";
              } else {
                updatedValue += currentType + ",";
              }
            }
            mistakeTypesPerLineMap.set(lineId, updatedValue);

            if (!lineIdToAnnotationIdsMap.has(lineId)) {
              // Update lineIdToAnnotationIdsMap
              lineIdToAnnotationIdsMap.set(lineId, [annotation.id]);
            } else {
              const existingIds = lineIdToAnnotationIdsMap.get(lineId);
              if (lineIds.length > 1 && lineId !== lineIds[0]) {
                existingIds.unshift(annotation.id); // Adds to the beginning for subsequent lines
              } else {
                existingIds.push(annotation.id); // Adds to the end for the first line or single-line annotations
              }
              lineIdToAnnotationIdsMap.set(lineId, existingIds);
            }
          });
        });
      });

    let maxXPosition = -Infinity;
    for (const [lineId, letters] of mistakeTypesPerLineMap.entries()) {
      const annotationIds = lineIdToAnnotationIdsMap.get(lineId);

      const lineBbox = props.jiix.lines.find((line) => line.lineId === lineId)[
        "bounding-box"
      ];

      const upperRightPointY = Math.round(lineBbox.newY ?? lineBbox.y);
      const lowerRightPointY = Math.round(
        lineBbox.newY ?? lineBbox.y + lineBbox.height
      );
      let middlePointY =
        upperRightPointY + (lowerRightPointY - upperRightPointY) * 0.25;

      // Split the letters string by commas and filter out empty strings
      const letterEntries = letters.split(/[,]/).filter((entry) => entry);

      let xPosition = 0;
      let yPosition = 0;

      // Iterate over each entry
      // eslint-disable-next-line no-loop-func
      letterEntries.forEach((entry, index) => {
        // Extract the color code, line index, and number of lines from the brackets
        const bracketContentMatch = entry.match(/\[#(.*?)-(.*?)\/(.*?)\]/);
        const colorCode = bracketContentMatch
          ? "#" + bracketContentMatch[1]
          : "red"; // Default to red if no color code is found
        const lineIndex = bracketContentMatch
          ? parseInt(bracketContentMatch[2])
          : 0;
        const numberOfLines = bracketContentMatch
          ? parseInt(bracketContentMatch[3])
          : 0;

        // Remove the color code, line index, and number of lines along with brackets from the entry
        let label = entry.replace(/\[.*?\]/, "").trim();

        let opacity;
        if (numberOfLines > 1) {
          if (lineIndex === 1) {
          } else if (lineIndex === numberOfLines) {
            opacity = 0.5;
          } else {
            opacity = 0.5;
          }
        }

        if (numberOfLines > 1) {
          if (lineIndex === 1 && index === letterEntries.length - 1) {
            label += "...";
          } else if (lineIndex === numberOfLines && index === 0) {
            // label = "..." + label;
            label = "...";
          }
        }

        label +=
          index < letterEntries.length - 1
            ? letterEntries[index + 1]
                .replace(/\[.*?\]/, "")
                .trim()
                .startsWith("|")
              ? ""
              : ","
            : "";
        // non necessary to cut now with the dynamic width and zoom
        // if (label.length > 20) {
        //   label = label.substring(0, 20) + "...";
        // }

        if (numberOfLines > 1 && lineIndex > 1 && index === 0) {
          label = "...";
        }

        if (numberOfLines > 1 && lineIndex > 1 && index > 0) {
          // annotationen nach zeilebumbrüchen nicht mehr anzeigen, nur mit "..."
          return;
        }

        let textObject = new fabric.Text(label, {
          left: (startingPosLanguageCorrectionMarks + xPosition) * SCALE_FACTOR,
          top:
            (middlePointY * MM_TO_PIXEL +
              additionalHeightTopAndBottom +
              yPosition) *
            SCALE_FACTOR,
          fill: colorCode,
          opacity: opacity ? opacity : 1,
          stroke: colorCode,
          fontSize: 35 * SCALE_FACTOR,
          hasControls: false,
          hasBorders: false,
          selectable: false,
          hasRotatingPoint: false,
          annotationId: annotationIds[index],
          customColor: colorCode,
          correctionMark: true,
        });

        fabricCanvasRef.current.add(textObject);

        // Update the x position for the next label
        xPosition += textObject.width / SCALE_FACTOR + 5 / SCALE_FACTOR; // Add a small gap between labels
        if (xPosition > maxXPosition) {
          maxXPosition = xPosition;
        }

        if (label.length > 20) {
          xPosition = 0;
          yPosition += 40 / SCALE_FACTOR;
        }
      });
    }

    let linesInhaltlicherAufbau = [];
    let linesUmgangMitDemThema = [];

    let countInhaltlicherAufbau = 0;
    let countUmgangMitDemThema = 0;

    annotationsRef.current
      .filter((annotation) => annotation.category === "content")
      .sort((a, b) => {
        if (a.contentType < b.contentType) return -1;
        if (a.contentType > b.contentType) return 1;
        return a.startLineId - b.startLineId;
      })
      .forEach((annotation, annotationIndex) => {
        const startLine = props.jiix.lines.find(
          (line) => line.lineId === annotation.startLineId
        );
        const endLine = props.jiix.lines.find(
          (line) => line.lineId === annotation.endLineId
        );

        const startlineBbox = startLine["bounding-box"];
        const endlineBbox = endLine["bounding-box"];
        // Calculate the scaled positions for the vertical line
        const upperLeftPointX =
          (Math.round(startlineBbox.x) * MM_TO_PIXEL + 50) * SCALE_FACTOR;
        const upperLeftPointY =
          Math.round(startlineBbox.y) * MM_TO_PIXEL * SCALE_FACTOR;
        const lowerRightPointX = upperLeftPointX;
        const lowerLeftPointY =
          annotation.startLineId === annotation.endLineId
            ? Math.round(
                (startlineBbox.newY ?? startlineBbox.y + startlineBbox.height) *
                  MM_TO_PIXEL *
                  SCALE_FACTOR
              )
            : Math.round(
                (endlineBbox.newY ?? endlineBbox.y + endlineBbox.height) *
                  MM_TO_PIXEL *
                  SCALE_FACTOR
              );

        const annotationColor = getCorrectionMarkColor(
          props.correctionMarks,
          // annotation.type
          annotation.type.split("|")[0]
        );

        // Create a vertical line with scaled positions
        const verticalLine = new fabric.Line(
          [
            annotation.contentType === "Inhaltlicher Aufbau"
              ? upperLeftPointX
              : upperLeftPointX + 80 * SCALE_FACTOR,
            upperLeftPointY,
            annotation.contentType === "Inhaltlicher Aufbau"
              ? lowerRightPointX
              : lowerRightPointX + 80 * SCALE_FACTOR,
            lowerLeftPointY,
          ],
          {
            stroke: annotationColor,
            strokeWidth: 5 * SCALE_FACTOR,
            strokeDashArray:
              annotation.contentType === "Inhaltlicher Aufbau"
                ? [5 * SCALE_FACTOR, 5 * SCALE_FACTOR]
                : null,
            selectable: false,
            hasRotatingPoint: false,
            customColor: annotationColor,
            hasControls: false,
            hasBorders: false,
          }
        );

        const contentLabel =
          annotation.contentType === "Inhaltlicher Aufbau"
            ? `A${++countInhaltlicherAufbau}`
            : `I${++countUmgangMitDemThema}`;

        // Adjust label position using SCALE_FACTOR
        let textObject = new fabric.Text(contentLabel, {
          left: verticalLine.left - 10 * SCALE_FACTOR,
          top: verticalLine.top - 40 * SCALE_FACTOR,
          fill: annotationColor,
          stroke: annotationColor,
          fontSize: 35 * SCALE_FACTOR,
          hasControls: false,
          hasBorders: false,
          selectable: false,
          hasRotatingPoint: false,
          customColor: annotationColor,
          annotationId: annotation.id,
        });

        let group = new fabric.Group([verticalLine, textObject], {
          annotationId: annotation.id,
          annotationCategory: "content",
          evented: props.interactive,
          selectable: props.interactive,
          dontShowDeleteControl: props.dontShowDeleteControl,
          startLineId: annotation.startLineId,
          endLineId: annotation.endLineId,
          customColor: annotationColor,
        });

        if (annotation.contentType === "Inhaltlicher Aufbau") {
          linesInhaltlicherAufbau.push(group);
        } else {
          linesUmgangMitDemThema.push(group);
        }
      });

    linesInhaltlicherAufbau.sort((a, b) => {
      return a.startLineId - b.startLineId;
    });

    linesUmgangMitDemThema.sort((a, b) => {
      return a.startLineId - b.startLineId;
    });

    linesInhaltlicherAufbau.forEach((currentGroup, currentIndex) => {
      if (currentIndex % 2 > 0) {
        let previousIndex = currentIndex - 1;
        const previousGroup = linesInhaltlicherAufbau[previousIndex];
        if (
          parseInt(previousGroup.endLineId) <=
            parseInt(currentGroup.startLineId) &&
          previousGroup.left === currentGroup.left
        ) {
          currentGroup.left += 40 * SCALE_FACTOR; // Move the group 70px to the right
        }
      }

      currentGroup.top += additionalHeightTopAndBottom * SCALE_FACTOR;

      fabricCanvasRef.current.add(currentGroup);
      fabricCanvasRef.current.bringToFront(currentGroup);
    });

    linesUmgangMitDemThema.forEach((currentGroup, currentIndex) => {
      if (currentIndex % 2 > 0) {
        let previousIndex = currentIndex - 1;
        const previousGroup = linesUmgangMitDemThema[previousIndex];

        if (
          parseInt(previousGroup.endLineId) <=
            parseInt(currentGroup.startLineId) &&
          previousGroup.left === currentGroup.left
        ) {
          currentGroup.left += 40 * SCALE_FACTOR; // Move the group 70px to the right
        }
      }

      currentGroup.top += additionalHeightTopAndBottom * SCALE_FACTOR;

      fabricCanvasRef.current.add(currentGroup);
      fabricCanvasRef.current.bringToFront(currentGroup);
    });

    // Calculate the zoom factor based on the maxXPosition
    const canvasWidth = containerRef.current.offsetWidth;

    if (maxXPosition === -Infinity) {
      maxXPosition = 0;
    }

    const requiredWidth =
      (startingPosLanguageCorrectionMarks +
        maxXPosition +
        additionalWidthRight) *
      SCALE_FACTOR;

    const zoomFactor = canvasWidth / requiredWidth; // Doppelter Zoom

    if (!isMobile) {
      const height =
        (props.jiix["bounding-box"].height * MM_TO_PIXEL +
          additionalHeightTopAndBottom * 2) *
        SCALE_FACTOR;

      fabricCanvasRef.current.setWidth(requiredWidth);
      fabricCanvasRef.current.setHeight(height);

      props.onScaleChange(zoomFactor);
    } else {
      const height =
        (props.jiix["bounding-box"].height * MM_TO_PIXEL * zoomFactor +
          additionalHeightTopAndBottom * 2) *
        SCALE_FACTOR;

      fabricCanvasRef.current.setWidth(requiredWidth);
      fabricCanvasRef.current.setHeight(height);

      fabricCanvasRef.current.setZoom(zoomFactor);
    }

    fabricCanvasRef.current.discardActiveObject(); // Unselect the active object
    fabricCanvasRef.current.renderAll(); // Re-render the canvas to apply changes
  }, [props.annotations]);

  const intersectRect = (r1, r2) => {
    return !(
      r2.left > r1.left + r1.width ||
      r2.left + r2.width < r1.left ||
      r2.top > r1.top + r1.height ||
      r2.top + r2.height < r1.top
    );
  };

  const processJIIX = () => {
    // Bug fix for touch event detection: https://github.com/fabricjs/fabric.js/issues/5903#issuecomment-699088432
    const defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
    fabric.util.object.extend(fabric.Canvas.prototype, {
      _onTouchStart: function (e) {
        var target = this.findTarget(e);
        if (this.allowTouchScrolling && !target && !this.isDrawingMode) {
          return;
        }
        defaultOnTouchStartHandler.call(this, e);
      },
    });

    // https://jhildenbiddle.github.io/canvas-size/#/?id=desktop
    // größe canvas ist beschränkt je nach device
    const canvas = new fabric.Canvas(htmlCanvasRef.current, {
      allowTouchScrolling: true,
      renderOnAddRemove: false, // Add this line
      objectCaching: false,
      statefullCache: false,
      dirty: true,
      noScaleCache: true,
      imageSmoothingEnabled: true,
      enableRetinaScaling: false,
    });
    canvas.allowTouchScrolling = true;

    // Disable object caching to prevent crashes
    // http://fabricjs.com/fabric-object-caching
    fabricCanvasRef.current = canvas;

    fabric.Object.prototype.objectCaching = false;

    function deselectAllObjects() {
      resetCanvasObjects();
    }
    const handleDblClick = async (e) => {
      if (
        e.target.type === "group" &&
        "annotationId" in e.target &&
        e.target.annotationCategory === "linguistic"
      ) {
        console.log(
          "Double-clicked annotation with annotationId:",
          e.target.annotationId
        );

        const annotation = annotationsRef.current.find(
          (a) => a.id === e.target.annotationId
        );

        const newType = annotation.type === "H" ? "R" : "H";
        const newCorrect = annotation.correct;
        const newStartPos = annotation.startPos;
        const newEndPos = annotation.endPos;
        const newComment = annotation.comment ? annotation.comment : "";

        await props.deleteAnnotation(e.target.annotationId);

        await props.addAnnotationToDB(
          annotationCategory,
          newType,
          "",
          newCorrect,
          newStartPos,
          newEndPos,
          0,
          0,
          false,
          newComment,
          "user"
        );

        resetCanvasObjects();
      } else if (e.target.annotationCategory !== "content") {
        await props.addAnnotationToDB(
          "linguistic",
          "Sp+",
          "",
          e.target.label,
          e.target.startPos,
          e.target.endPos,
          0,
          0,
          false,
          "",
          "user"
        );

        resetCanvasObjects();
      }
    };

    canvas.on("mouse:dblclick", handleDblClick);

    canvas.on("mouse:down", function (e) {
      if (!isMultiSelectEnabledRef.current) {
        deselectAllObjects();
      }

      if (e.target) {
        canvas.setActiveObject(e.target);
        canvas.renderAll();

        if (e.target.type === "rect") {
          const alreadySelectedWordIndex = selectedObjectsRef.current.findIndex(
            (obj) => obj && obj.wordId === e.target.wordId
          );

          if (alreadySelectedWordIndex === -1) {
            e.target.set({ fill: "red", opacity: 0.15 });
            canvas.renderAll();

            if (isMultiSelectEnabledRef.current) {
              // If there is already one word selected, add all words between the two selected words
              if (selectedObjectsRef.current.length > 0) {
                const firstWordOrder = selectedObjectsRef.current[0].wordOrder;
                const currentWordOrder = e.target.wordOrder;

                // Find the min and max word orders to select in-between words
                const minWordOrder = Math.min(firstWordOrder, currentWordOrder);
                const maxWordOrder = Math.max(firstWordOrder, currentWordOrder);

                canvas.getObjects().forEach(function (object) {
                  if (
                    object.type === "rect" &&
                    object.wordOrder > minWordOrder &&
                    object.wordOrder < maxWordOrder
                  ) {
                    object.set({ fill: "red", opacity: 0.15 });
                    const indexInSelected =
                      selectedObjectsRef.current.findIndex(
                        (obj) => obj && obj.wordId === object.wordId
                      );
                    if (indexInSelected === -1) {
                      selectedObjectsRef.current.push(object);
                    }
                  }
                });
              }
              selectedObjectsRef.current.push(e.target);
            }
          }
        } else if (
          e.target.type === "group" &&
          "annotationId" in e.target &&
          e.target.annotationCategory === "linguistic"
        ) {
          const annotationsWithSameId = fabricCanvasRef.current
            .getObjects()
            .filter(
              (object) =>
                object.type === "group" &&
                object.annotationId === e.target.annotationId
            );

          annotationsWithSameId.forEach((group) => {
            group.forEachObject(function (object) {
              object.set({ stroke: "blue" });
            });
          });

          const correctionMarksWithSameId = fabricCanvasRef.current
            .getObjects()
            .filter((object) => object.annotationId === e.target.annotationId);
          correctionMarksWithSameId.forEach((object) => {
            object.set({ fill: "blue", underline: true });
          });
        } else if (
          e.target.type === "group" &&
          "annotationId" in e.target &&
          e.target.annotationCategory === "content"
        ) {
          const wordsInLines = fabricCanvasRef.current
            .getObjects()
            .filter(
              (object) =>
                object.type === "rect" &&
                object.lineId >= e.target.startLineId &&
                object.lineId <= e.target.endLineId
            );

          wordsInLines.forEach((object) => {
            object.set({ fill: "red", opacity: 0.15 });
          });

          const annotationsWithSameId = fabricCanvasRef.current
            .getObjects()
            .filter(
              (object) =>
                object.type === "group" &&
                object.annotationId === e.target.annotationId
            );

          annotationsWithSameId.forEach((group) => {
            group.forEachObject(function (object) {
              object.set({ stroke: "blue" });
            });
          });
        } else {
          console.log("mouse:down | else ");
        }
      } else {
        deselectAllObjects();
      }
    });

    const deleteImg = document.createElement("img");
    deleteImg.src = deleteIcon;

    const editImg = document.createElement("img");
    editImg.src = editTextIcon;

    const multiSelectImg = document.createElement("img");
    multiSelectImg.src = multiSelectIcon;

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "blue";
    fabric.Object.prototype.cornerStyle = "circle";

    // Disable the default controls you don't want to show
    fabric.Object.prototype.set({
      hasRotatingPoint: false,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: true,
      lockRotation: true,
    });

    fabric.Object.prototype.setControlsVisibility({
      mt: false, // middle top
      tr: false, // top right
      mr: false, // middle right
      br: false, // bottom right
      mb: false, // middle bottom
      bl: false, // bottom left
      ml: false, // middle left
      tl: false, // top left
      mtr: false, // rotate control
    });

    function renderIcon(icon) {
      return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));

        // Draw the icon
        ctx.drawImage(icon, -size / 2, -size / 2, size, size);
        ctx.restore();
      };
    }

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -2,
      offsetX: 2,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon(deleteImg),
      cornerSize: isMobile ? 24 : 48,
      getVisibility: function (object) {
        return (
          object.type === "group" &&
          !object.dontShowDeleteControl &&
          object.annotationType !== "H"
        );
      },
    });

    fabric.Object.prototype.controls.clone = new fabric.Control({
      x: -0.5,
      y: -0.5,
      offsetY: -2,
      offsetX: -2,
      cursorStyle: "pointer",
      mouseUpHandler: addAnnotation,
      render: renderIcon(editImg),
      cornerSize: isMobile ? 24 : 48,
      getVisibility: function (object) {
        return (
          !isMultiSelectEnabledRef.current || !object.dontShowMultiSelectControl
        );
      },
    });

    fabric.Object.prototype.controls.multiSelectControl = new fabric.Control({
      x: 0.5,
      y: 0.5,
      offsetY: 0,
      offsetX: 0,
      cursorStyle: "pointer",
      mouseDownHandler: toggleMultiSelectMode,
      render: renderIcon(multiSelectImg),
      cornerSize: isMobile ? 24 : 48,
      getVisibility: function (object) {
        return !("annotationId" in object);
      },
    });

    // Ensure hoverCursor is set correctly for all objects
    fabric.Object.prototype.hoverCursor = "pointer";

    async function deleteObject(eventData, transform) {
      const target = transform.target;
      const targetAnnotationId = target.annotationId;

      let annotation = annotationsRef.current.filter(
        (obj) => obj.id === targetAnnotationId
      )[0];

      const newType = annotation.type === "H" ? "R" : "H";
      const newCorrect = annotation.correct;
      const newStartPos = annotation.startPos;
      const newEndPos = annotation.endPos;
      const newComment = annotation.comment ? annotation.comment : "";

      await props.deleteAnnotation(targetAnnotationId);

      if (
        user.uid === "6fozyj8RQJO33Q8vWXQLMbYUzct2" ||
        user.uid === "uQwUUlHweNhJo4AIRphEgdv6Hqw1"
      ) {
        // nix
      } else {
        // Delete the annotation and then re-add

        await props.addAnnotationToDB(
          annotationCategory,
          newType,
          "",
          newCorrect,
          newStartPos,
          newEndPos,
          0,
          0,
          false,
          newComment,
          "user"
        );
      }

      resetCanvasObjects();
    }

    // addImage();
    // addJiixWords();

    Promise.all([addImage(), addJiixWords()])
      .then(() => {
        fabricCanvasRef.current.renderAll();
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    if (fabricCanvasRef.current) {
      addAnnotations().finally(() => {
        props.setLoadingAnnotations(false);
      });
    }
  }, [props.annotations, props.correctionMarks]);

  const addImage = () => {
    console.log(`addImage started`);

    const loadAndAddImage = async (url, index, totalImages) => {
      return new Promise((resolve, reject) => {
        console.log(
          `Loading image ${index + 1}/${totalImages} from URL: ${url}`
        );
        fabric.Image.fromURL(
          url,
          (image) => {
            if (!image) {
              console.error(`Failed to load image ${index + 1}`);
              reject(new Error(`Failed to load image ${index + 1}`));
              return;
            }

            console.log(`Image ${index + 1} loaded successfully`);
            const cumulativeHeight = index * image.height;
            image.set({
              left: additionalWidthLeft * SCALE_FACTOR,
              top:
                (cumulativeHeight + additionalHeightTopAndBottom) *
                SCALE_FACTOR,
              scaleX: SCALE_FACTOR,
              scaleY: SCALE_FACTOR,
              selectable: false,
              evented: false,
            });

            fabricCanvasRef.current.add(image);
            fabricCanvasRef.current.sendToBack(image);

            if (index === 0) {
              // in case we have an empty jiix, we need to set the canvas dimensions based on the image
              if (props.jiix.words.length === 0) {
                // Calculate the zoom factor based on the maxXPosition
                const canvasWidth = containerRef.current.offsetWidth;
                const zoomFactor = (canvasWidth / image.width) * 2;
                const height =
                  props.jiix["bounding-box"].height * MM_TO_PIXEL +
                  additionalHeightTopAndBottom * 2; // 2x additionalHeight for top and bottom
                fabricCanvasRef.current.setWidth(image.width);
                fabricCanvasRef.current.setHeight(height);
                if (!isMobile) {
                  props.onScaleChange(zoomFactor);
                } else {
                  // ACHTUNG: Bilder werden in HTML5 Canvases im Safari verpixelt dargestellt, wenn zoom oder
                  // scale unter 0.5.Daher wird per onScaleChange die darunterliegende Komponente per CSS
                  // skaliert statt das Canvas hier
                  fabricCanvasRef.current.setZoom(zoomFactor);
                }
              }
            }

            fabricCanvasRef.current.renderAll();
            console.log(
              `Image ${index + 1} added to canvas. Canvas dimensions: ${
                fabricCanvasRef.current.width
              } x ${fabricCanvasRef.current.height}`
            );
            resolve();
          },
          { crossOrigin: "anonymous" }
        );
      });
    };

    const originalFolderRef = ref(
      storage,
      `users/${props.userId}/exams/${props.examId}/images_original`
    );
    const regularFolderRef = ref(
      storage,
      `users/${props.userId}/exams/${props.examId}/images`
    );

    listAll(originalFolderRef)
      .then((res) => {
        const taskFolderRef =
          res.items.length > 0 ? originalFolderRef : regularFolderRef;
        console.log(`Using folder: ${taskFolderRef.fullPath}`);

        listAll(taskFolderRef)
          .then(async (res) => {
            const imageFiles = res.items
              .filter(
                (item) =>
                  item.name.startsWith(`${props.submissionId}_page`) &&
                  item.name.endsWith(".jpg")
              )
              .sort((a, b) => {
                const aMatch = a.name.match(/_page(\d+)\.jpg$/);
                const bMatch = b.name.match(/_page(\d+)\.jpg$/);
                return (
                  (aMatch ? parseInt(aMatch[1], 10) : 0) -
                  (bMatch ? parseInt(bMatch[1], 10) : 0)
                );
              });

            console.log(`Found ${imageFiles.length} image files`);

            if (imageFiles.length === 0) {
              console.error("No image files found");
              return;
            }

            try {
              // Load and add the first image immediately
              const firstImageUrl = await getDownloadURL(imageFiles[0]);
              console.log(`First image URL: ${firstImageUrl}`);
              await loadAndAddImage(firstImageUrl, 0, imageFiles.length);

              // Load and add the rest of the images asynchronously
              for (let i = 1; i < imageFiles.length; i++) {
                getDownloadURL(imageFiles[i])
                  .then((url) => {
                    loadAndAddImage(url, i, imageFiles.length);
                  })
                  .catch((error) => {
                    console.error(
                      `Error getting download URL for image ${i + 1}:`,
                      error
                    );
                  });
              }
            } catch (error) {
              console.error("Error loading images:", error);
            }
          })
          .catch((error) => {
            console.error("Error listing task files: ", error);
          });
      })
      .catch((error) => {
        console.error("Error checking folders: ", error);
      });
  };

  const addJiixWords = () => {
    if (props.jiix && props.jiix.words) {
      let wordOrder = 0;
      let currentStartPos = 0;
      let currentEndPos = 0;
      props.jiix.words.forEach((word) => {
        currentEndPos = currentStartPos + word.label.length;
        if ("bounding-box" in word) {
          const jiix_word_bbox = word["bounding-box"];
          const word_x_coord = Math.round(
            jiix_word_bbox.x * MM_TO_PIXEL * SCALE_FACTOR
          );
          const word_y_coord = Math.round(
            jiix_word_bbox.y * MM_TO_PIXEL * SCALE_FACTOR
          );
          const word_width = Math.round(
            jiix_word_bbox.width * MM_TO_PIXEL * SCALE_FACTOR
          );
          const word_height = Math.round(
            jiix_word_bbox.height * MM_TO_PIXEL * SCALE_FACTOR
          );

          var wordRect = new fabric.Rect({
            left: additionalWidthLeft * SCALE_FACTOR + word_x_coord,
            top: word_y_coord + additionalHeightTopAndBottom * SCALE_FACTOR,
            fill: "none",
            width: word_width,
            height: word_height,
            objectCaching: false,
            transparentCorners: false,
            opacity: props.visualizeBBs ? 0.05 : 0,
            dontShowDeleteControl: true,
            dontShowMultiSelectControl: true,
            startPos: currentStartPos,
            endPos: currentEndPos,
            wordOrder: ++wordOrder,
            label: word.label,
            suggestion: word.label,
            wordId: word.wordId,
            lineId: word.lineId,
            evented: props.interactive,
            selectable: props.interactive,
            wordPosInLine: word.wordPosInLine,
          });

          fabricCanvasRef.current.add(wordRect);
        }
        currentStartPos = currentEndPos;
      });
    }
  };

  function splitArrayIntoParts(array, numOfParts) {
    const result = [];
    const partSize = Math.ceil(array.length / numOfParts);

    for (let i = 0; i < array.length; i += partSize) {
      const part = array.slice(i, i + partSize);
      result.push(part);
    }

    return result;
  }

  const correctedWordInputRef = useRef(null);

  useEffect(() => {
    if (isModalVisible && correctedWordInputRef.current) {
      const timer = setTimeout(() => {
        correctedWordInputRef.current.focus();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isModalVisible]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleOk();
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalVisible, handleOk]);

  return (
    <div ref={containerRef}>
      <canvas
        ref={htmlCanvasRef}
        width={props.jiix["bounding-box"].width * MM_TO_PIXEL}
        height={props.jiix["bounding-box"].height * MM_TO_PIXEL}
      />
      <Modal
        bodyStyle={{
          height: "60vh",
          overflowY: "auto",
        }}
        style={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          width: "40%",
          overflowY: "auto",
        }}
        title={
          annotationCategory === "linguistic"
            ? "Korrektur bearbeiten"
            : "Inhaltliche Begründungstexte bearbeiten"
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <React.Fragment>
          {!annotationToUpdateId && props.submissionData?.gradesSuggested && (
            <>
              <Radio.Group
                onChange={(e) => {
                  setAnnotationCategory(e.target.value);
                  if (e.target.value === "content") {
                    setAnnotationType("inhaltlich richtig");
                    setContentType("Umgang mit dem Thema");
                    setCorrectedWord("");
                  } else {
                    setAnnotationType("R");
                  }
                }}
                value={annotationCategory}
              >
                <Radio.Button value="linguistic">
                  Sprachliche Korrektur
                </Radio.Button>
                <Radio.Button value="content">
                  Inhaltliche Korrektur
                </Radio.Button>
              </Radio.Group>
            </>
          )}

          <div>
            <p>
              {annotationCategory === "linguistic"
                ? "Korrigierter Text:"
                : "Begründung:"}
            </p>
            <TextArea
              ref={correctedWordInputRef}
              value={correctedWord}
              onChange={(e) => {
                setCorrectedWord(e.target.value);
              }}
              autoSize={{ minRows: 1, maxRows: 10 }}
              placeholder={
                annotationToUpdateId.length > 0 &&
                correctedWord.length === 0 &&
                annotationCategory === "linguistic"
                  ? "leeres Wort (durchgestrichen dargestellt)"
                  : null
              }
              maxLength={256}
            />
            {(user.uid === "6fozyj8RQJO33Q8vWXQLMbYUzct2" ||
              user.uid === "uQwUUlHweNhJo4AIRphEgdv6Hqw1") &&
              annotationCategory === "linguistic" && (
                <>
                  <small>
                    Transcription:{" "}
                    {props.jiix.label.substring(
                      startPosRef.current,
                      endPosRef.current
                    )}
                  </small>
                </>
              )}
          </div>
          <div style={{ marginTop: "1rem" }}>
            <p>Fehlertyp(en):</p>

            {annotationCategory === "linguistic" ? (
              <Checkbox.Group
                options={props.correctionMarks
                  .filter((mark) => mark.annotationCategory === "linguistic")
                  .map((mark) => ({
                    label: `${mark.customAbbreviation || mark.abbreviation} - ${
                      mark.type
                    }`,
                    value: mark.abbreviation,
                  }))}
                value={annotationType.split("|")}
                onChange={(checkedValues) => {
                  if (checkedValues.length === 0) {
                    message.error(
                      "Mindestens eine Option muss ausgewählt werden."
                    );
                  } else {
                    setAnnotationType(checkedValues.join("|"));
                  }
                }}
                style={{ display: "flex", flexDirection: "column" }}
              />
            ) : (
              <Radio.Group
                options={props.correctionMarks
                  .filter((mark) => mark.annotationCategory === "content")
                  .map((mark) => ({
                    label: mark.type,
                    value: mark.type,
                  }))}
                value={annotationType}
                onChange={(e) => {
                  setAnnotationType(e.target.value);
                }}
                style={{ display: "flex", flexDirection: "column" }}
              />
            )}
          </div>

          {annotationCategory === "content" && (
            <div style={{ marginTop: "1rem" }}>
              <p>Bewertungsbereich:</p>
              <Radio.Group
                options={[
                  {
                    label: "Inhaltliche Erschließung (Umgang mit dem Thema)",
                    value: "Umgang mit dem Thema",
                  },
                  {
                    label:
                      "Inhaltlicher Aufbau (Struktur, Stringenz, Kohärenz)",
                    value: "Inhaltlicher Aufbau",
                  },
                ]}
                value={contentType}
                onChange={(e) => setContentType(e.target.value)}
                style={{ display: "flex", flexDirection: "column" }}
              />
            </div>
          )}
        </React.Fragment>
      </Modal>
    </div>
  );
};

export default memo(HandwritingCanvas);
